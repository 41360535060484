import { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { notification, Button } from 'antd';

import {
  selectTransaction,
  selectSortedTransactions,
  selectTransactionError,
  selectFilters,
} from '../store/modules/transaction';
import { selectMuted } from '../store/modules/settings';
import { fetchTransactionInitiated } from '../store/modules/transaction/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { formatMoney, diffMinutes, openError } from '../utils/libs';

export const useGetTransaction = () => {
  const dispatch = useDispatch();
  const { cursor, limit } = useSelector(selectTransaction);
  const sortedTransactions = useSelector(selectSortedTransactions);
  const transactionFilters = useSelector(selectFilters);
  const muted = useSelector(selectMuted);

  const error = useSelector(selectTransactionError);
  const { pathname } = useLocation();
  const history = useHistory();

  const [alert, setAlert] = useState(0);
  const [audio] = useState(new Audio('/chime-alert.wav'));
  const [title] = useState('Fin');
  const count = useMemo(
    () => sortedTransactions.filter((t) => t.status === 'pending').length,
    [sortedTransactions],
  );

  const OpenNotification = useCallback(
    ({ transaction, pathname }) => {
      const key = transaction.transaction_id;
      const transactionRoute = '/home/transaction';

      const handleClick = () => {
        notification.close(key);
        history.push({ pathname: transactionRoute, search: `transactionId=${key}` });
      };

      const { title, text } = getNotificationTitleAndText(transaction);

      if (!title || !text) return;

      const btn = (
        <Button type="primary" size="small" onClick={handleClick}>
          Show me
        </Button>
      );
      notification.open({
        message: title,
        description: text,
        duration: 60, // 60 seconds
        btn: pathname !== transactionRoute ? btn : '',
        onClose: () => {
          setAlert(count);
        },
        key,
      });
    },
    [count, history],
  );

  const showNotification = useCallback(
    ({ transaction, pathname }) => {
      OpenNotification({ transaction, pathname });
    },
    [OpenNotification],
  );

  const updateTitle = useCallback(() => {
    if (count > alert) {
      // Show new transaction notification only if the difference is less than 1 min
      if (
        sortedTransactions[0].status === 'pending' &&
        diffMinutes(new Date(), new Date(sortedTransactions[0].updated)) < 1
      ) {
        showNotification({ transaction: sortedTransactions[0], pathname });
        !muted &&
          audio
            .play()
            .then(() => {
              if (count) {
                document.title = '(' + count + ') ' + title;
                setAlert(count);
              } else {
                document.title = title;
              }
            })
            .catch();
      }
    }
  }, [count, alert, sortedTransactions, showNotification, pathname, muted, audio, title]);

  const getNotificationTitleAndText = ({
    type,
    local_amount,
    foreign_amount,
    foreign_currency,
  }) => {
    switch (type.toLowerCase()) {
      case 'buyfx':
        return {
          title: 'New FX Purchase',
          text: `A trade to buy ${formatMoney(
            local_amount,
          )} HKD of ${foreign_currency} has just been submitted`,
        };
      case 'sellfx':
        return {
          title: 'New FX Sale',
          text: `A trade to sell ${formatMoney(
            local_amount,
          )} HKD equivalent of ${foreign_currency} has just been submitted`,
        };
      case 'transfertobank':
        return {
          title: 'New Withdrawal Request',
          text: `A customer has requested to withdraw ${formatMoney(
            local_amount,
          )} HKD via local bank transfer`,
        };
      case 'remittance':
        return {
          title: 'New Remittance Instruction',
          text: `A customer has requested to remit ${formatMoney(
            foreign_amount,
          )} ${foreign_currency}.`,
        };
      default:
        return {};
    }
  };

  useEffect(() => {
    sortedTransactions && updateTitle();
  }, [sortedTransactions, updateTitle]);

  useEffect(() => {
    error && openError('', JSON.stringify(error));
  }, [error]);

  const getTransaction = useCallback(() => {
    dispatch(
      fetchTransactionInitiated({
        ...transactionFilters,
        per_page: limit,
        cursor,
      }),
    );
  }, [dispatch, cursor, limit, transactionFilters]);

  useEffect(() => {
    const timeOut = setTimeout(() => getTransaction(), 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [sortedTransactions, getTransaction]);

  return null;
};
