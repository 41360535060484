import * as TYPES from './types';

export const fetchBalanceInitiated = (data) => ({
  type: TYPES.FETCH_BALANCE_INITIATED,
  payload: data,
});

export const fetchBalanceSucceeded = (data) => ({
  type: TYPES.FETCH_BALANCE_SUCCEEDED,
  payload: data,
});

export const fetchBalanceFailed = (data) => ({
  type: TYPES.FETCH_BALANCE_FAILED,
  payload: data,
});

export const filterBalanceInitiated = (payload) => ({
  type: TYPES.FILTER_BALANCE_INITIATED,
  payload,
});

export const getMoreBalanceInitiated = (payload) => ({
  type: TYPES.GET_MORE_BALANCE_INITIATED,
  payload,
});

export const getMoreBalanceSucceeded = (payload) => ({
  type: TYPES.GET_MORE_BALANCE_SUCCEEDED,
  payload,
});

export const getMoreBalanceFailed = (payload) => ({
  type: TYPES.GET_MORE_BALANCE_FAILED,
  payload,
});

export const getPrevBalanceInitiated = (payload) => ({
  type: TYPES.GET_PREVIOUS_BALANCE_INITIATED,
  payload,
});

export const getPrevBalanceSucceeded = (payload) => ({
  type: TYPES.GET_PREVIOUS_BALANCE_SUCCEEDED,
  payload,
});

export const getPrevBalanceFailed = (payload) => ({
  type: TYPES.GET_PREVIOUS_BALANCE_FAILED,
  payload,
});

export const setPaginationlimit = (payload) => ({
  type: TYPES.SET_PAGINATION_LIMIT,
  payload,
});
