import {
  Button,
  Col,
  Descriptions,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Skeleton,
  Table,
} from 'antd';
import { userBalanceToArray, formatMoney, openError, openNotification } from '../utils/libs';
import { useCallback, useState, useMemo } from 'react';
import { addTransactionInitiated } from '../store/modules/transaction/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectTransaction } from '../store/modules/transaction';
import NumberFormat from 'react-number-format';

const AmountInput = ({ name, label, initialValue, onValueChange }) => (
  <div className="amount-input">
    <span>{label}</span>
    <NumberFormat
      customInput={Input}
      name={name}
      onValueChange={onValueChange}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      defaultValue={initialValue}
      allowNegative={false}
    />
  </div>
);

const ConfirmOrRejectBtnWrapper = ({ children, onConfirm, setVisible, visible, title }) => {
  const { loading } = useSelector(selectTransaction);
  const handleCancel = () => setVisible(false);

  return (
    <Popconfirm
      title={title}
      visible={visible}
      onConfirm={onConfirm}
      okButtonProps={{ loading }}
      onCancel={handleCancel}
    >
      {children}
    </Popconfirm>
  );
};
const ConfirmOrRejectBtn = ({ transactionAction, item, loading }) => {
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);

  const onConfirm = () => {
    transactionAction(item, 'confirmed');
    setConfirmVisible(false);
  };
  const onReject = () => {
    transactionAction(item, 'rejected');
    setRejectVisible(false);
  };

  return (
    <Space>
      <ConfirmOrRejectBtnWrapper
        title={'Are you sure that you want to accept the transaction? This cannot be undone.'}
        onConfirm={onConfirm}
        setVisible={setConfirmVisible}
        visible={confirmVisible}
      >
        <Button disabled={loading} type="primary" onClick={() => setConfirmVisible(true)}>
          Accept
        </Button>
      </ConfirmOrRejectBtnWrapper>
      <ConfirmOrRejectBtnWrapper
        title={'Are you sure that you want to reject the transaction? This cannot be undone.'}
        onConfirm={onReject}
        setVisible={setRejectVisible}
        visible={rejectVisible}
      >
        <Button disabled={loading} type="danger" onClick={() => setRejectVisible(true)}>
          Reject
        </Button>
      </ConfirmOrRejectBtnWrapper>
    </Space>
  );
};

const ActionButton = ({ localLedger, setShowConfirmModal, transactionAction, item, loading }) => {
  const showDepositModal = () => setShowConfirmModal(true);
  const [rejectVisible, setRejectVisible] = useState(false);
  const onReject = () => transactionAction(item, 'rejected');

  switch (item.type) {
    case 'buyfx':
      if (localLedger >= item.local_amount) {
        return <ConfirmOrRejectBtn transactionAction={transactionAction} item={item} />;
      } else {
        return (
          <Space>
            <Button disabled={loading} type="primary" onClick={showDepositModal}>
              Deposit
            </Button>

            <ConfirmOrRejectBtnWrapper
              title={'Are you sure that you want to reject the transaction? This cannot be undone.'}
              onConfirm={onReject}
              setVisible={setRejectVisible}
              visible={rejectVisible}
            >
              <Button disabled={loading} type="danger" onClick={() => setRejectVisible(true)}>
                Reject
              </Button>
            </ConfirmOrRejectBtnWrapper>
          </Space>
        );
      }
    case 'sellfx':
    case 'remittance':
    case 'transfertobank': {
      return (
        <ConfirmOrRejectBtn transactionAction={transactionAction} item={item} loading={loading} />
      );
    }
    default:
      return <></>;
  }
};
const TransactionActions = ({
  user,
  balance,
  item,
  loading,
  transactionAction,
  transactionInfoLoading,
}) => {
  const {
    company_name = '',
    email = '',
    phone_number = '',
    uid = '',
    nkgl = 'Not Set',
    nkcid = 'Not Set',
  } = user || {};
  const { local_currency, local_amount } = item;

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const addFee = useMemo(() => {
    if (item.type === 'remittance') {
      item.fee = 80;
      return true;
    }
    return false;
  }, [item]);

  const highlightLedgerBalance = (ledgerForeignAmount, row, item) => {
    if (
      (item.type === 'sellfx' || item.type === 'remittance') &&
      row.currency === item.foreign_currency &&
      ledgerForeignAmount < item.foreign_amount
    ) {
      return <span style={{ color: '#FF0000' }}>{ledgerForeignAmount}</span>;
    } else {
      return <span>{ledgerForeignAmount}</span>;
    }
  };

  const highlightAvailableBalance = (availableAmount, row, item) => {
    if (
      item.type === 'transfertobank' &&
      row.currency === item.local_currency &&
      parseFloat(availableAmount) < item.local_amount
    ) {
      return <span style={{ color: '#FF0000' }}>{availableAmount}</span>;
    } else {
      return <span>{availableAmount}</span>;
    }
  };

  let localLedger = 0;
  balance &&
    Object.keys(balance).forEach((key) => {
      if (key === local_currency) localLedger = balance[key].ledger;
    });

  return (
    <>
      {transactionInfoLoading ? (
        <Skeleton />
      ) : (
        <>
          {user && balance && (
            <>
              <Descriptions className="user-desc-tile user_info_header" column={1}>
                <Descriptions.Item label="Company name">{company_name}</Descriptions.Item>
                <Descriptions.Item label="Email">{email}</Descriptions.Item>
                <Descriptions.Item label="Phone number">{phone_number}</Descriptions.Item>
                <Descriptions.Item label="NK Customer ID">{nkcid}</Descriptions.Item>
                <Descriptions.Item label="NK General Ledger ID">{nkgl}</Descriptions.Item>
              </Descriptions>
              <Divider />
              <Descriptions className="user-desc-tile user_info_header" column={1}>
                {item.local_currency && (
                  <Descriptions.Item label="Local Amount">
                    {formatMoney(item.local_amount)} {item.local_currency}
                  </Descriptions.Item>
                )}
                {item.foreign_currency && (
                  <Descriptions.Item label="Foreign Amount">
                    {formatMoney(item.foreign_amount)} {item.foreign_currency}
                  </Descriptions.Item>
                )}
                {item.rate && (
                  <Descriptions.Item label="Exchange Rate">{item.rate}</Descriptions.Item>
                )}
              </Descriptions>
              <Table
                columns={[
                  {
                    title: 'Currency',
                    dataIndex: 'currency',
                    key: 'currency',
                  },
                  {
                    title: 'Ledger balance',
                    dataIndex: 'ledgerBalance',
                    key: 'ledgerBalance',
                    render: (ledgerBalance, row) => (
                      <span>{highlightLedgerBalance(ledgerBalance, row, item)}</span>
                    ),
                  },
                  {
                    title: 'Available balance',
                    dataIndex: 'availableBalance',
                    key: 'availableBalance',
                    render: (availableBalance, row) => (
                      <span>{highlightAvailableBalance(availableBalance, row, item)}</span>
                    ),
                  },
                ]}
                dataSource={userBalanceToArray(balance)}
                size="small"
                pagination={false}
                title={() => <div className="ant-descriptions-title">User Balance</div>}
              />
              {item.type === 'buyfx' && localLedger < item.local_amount && (
                <span style={{ color: '#FF0000' }}>
                  <br /> {formatMoney(item.local_amount - localLedger)} {item.local_currency} more
                  required for the transaction
                </span>
              )}
              <Divider />
              {addFee && (
                <>
                  <AmountInput
                    label="Fee (HKD)"
                    name="fee"
                    initialValue={80}
                    onValueChange={({ floatValue }) => {
                      item.fee = floatValue;
                    }}
                  />
                  <Divider />
                </>
              )}
              {item.status === 'pending' && (
                <ActionButton
                  localLedger={localLedger}
                  setShowConfirmModal={setShowConfirmModal}
                  transactionAction={transactionAction}
                  item={item}
                  loading={loading}
                />
              )}
              <DepositModal
                showConfirmModal={showConfirmModal}
                setShowConfirmModal={setShowConfirmModal}
                uid={uid}
                localAmount={local_amount}
                localLedger={localLedger}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const DepositModal = ({ showConfirmModal, setShowConfirmModal, uid, localAmount, localLedger }) => {
  const { loading } = useSelector(selectTransaction);
  const [creditAmount, setCreditAmount] = useState(localAmount);
  const dispatch = useDispatch();

  const successCallback = useCallback(
    (placement, requestionInfo) => {
      openNotification(placement, requestionInfo);
      setShowConfirmModal(false);
    },
    [setShowConfirmModal],
  );
  const failureCallback = useCallback(
    (errorMessage) => {
      openError(
        '',
        `Error while add ${formatMoney(
          creditAmount || 0,
        )} HKD to the user’s HKD available balance: ${errorMessage || 'Unhandled error'}`,
      );
    },
    [creditAmount],
  );

  const handleCreditTransaction = useCallback(
    (creditAmount, uid) => {
      dispatch(
        addTransactionInitiated({
          local_amount: parseFloat(creditAmount) || parseFloat(localAmount),
          local_currency: 'HKD',
          type: 'credit',
          uid: uid,
          successCallback: successCallback,
          failureCallback: failureCallback,
        }),
      );
    },
    [dispatch, localAmount, failureCallback, successCallback],
  );

  const handleModalCancel = () => setShowConfirmModal(false);
  return (
    <Modal
      title="Deposit HKD"
      centered
      visible={showConfirmModal}
      onOk={() => handleCreditTransaction(creditAmount, uid)}
      onCancel={handleModalCancel}
      okButtonProps={{ loading }}
    >
      <Row>
        <Space>
          <Col>Customer deposit amount:</Col>
          <Col>
            <Input
              type="number"
              defaultValue={creditAmount || localAmount}
              onChange={(e) => setCreditAmount(parseFloat(e.target.value))}
            />
          </Col>
          <Col>HKD</Col>
        </Space>
      </Row>
      <Divider />
      <Row>
        <p>
          By confirming, you will add {formatMoney(creditAmount || 0)} HKD to the user’s HKD
          available balance. The required amount for the transaction is {formatMoney(localAmount)}{' '}
          HKD. The HKD deficit for this transaction is {formatMoney(localAmount - localLedger)} HKD.
        </p>
      </Row>
    </Modal>
  );
};

export default TransactionActions;
