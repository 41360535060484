import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Skeleton, Typography } from 'antd';
import ReactToPrint from 'react-to-print';
import { useLocation } from 'react-router-dom';
import { JsonToTable } from 'react-json-to-table';
import { capitalCase } from 'capital-case';
import omit from 'lodash/omit';

import { selectTransaction } from '../../store/modules/transaction';
import { fetchTransactionInfoInitiated } from '../../store/modules/transaction/actions';
const { Title, Text } = Typography;

// Recursively capitalize keys of an object
const capitalizeKeys = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      capitalCase(key),
      typeof value == 'object' ? capitalizeKeys(value) : value,
    ]),
  );
};

export default function Print() {
  const info = useLocation().state;
  const dispatch = useDispatch();
  const componentRef = useRef(null);
  const { fetchTransactionInfoSuccessResponse, loading } = useSelector(selectTransaction);

  const getTransactionInfo = useCallback(() => {
    dispatch(fetchTransactionInfoInitiated({ transaction_id: info.transaction_id }));
  }, [dispatch, info]);

  // Load transaction info (including user NKID and NKGID)
  useEffect(() => {
    getTransactionInfo();
  }, [info, getTransactionInfo]);

  const {
    nkgl = 'Not Set',
    nkcid = 'Not Set',
    company_name = 'Not Set',
  } = fetchTransactionInfoSuccessResponse?.data?.user || {};

  // Format the transaction info object and remove internal properties not
  // needed for printing
  const formatedInfo = capitalizeKeys(
    omit(info, ['uid', 'admin_id', 'balance_updated_1', 'balance_updated_2', 'recipient.uid']),
  );

  return (
    <div>
      <div style={{ padding: 10 }}>
        <ReactToPrint
          trigger={() => <Button type="primary">Print Record</Button>}
          content={() => componentRef.current}
          documentTitle={`${info.transaction_id}.pdf`}
        />
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <div ref={componentRef} style={{ backgroundColor: 'white', padding: 30 }}>
          {formatedInfo === null ? (
            <Title>No data to print</Title>
          ) : (
            <>
              <Title level={2}>ReadyFX - Transaction Summary</Title>
              <Text strong>PRIVATE AND CONFIDENTIAL CLIENT DATA</Text>
              <br />
              <Text italic>NGAU KEE MONEY CHANGER CO. LTD</Text>
              <Title level={3}>Transaction ID {info.transaction_id}</Title>
              <JsonToTable
                json={{
                  'NK General Ledger ID': nkgl,
                  'NK Customer ID': nkcid,
                  'Customer Name': company_name,
                  ...formatedInfo,
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
