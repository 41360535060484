import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Popconfirm, Tooltip } from 'antd';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { MinusCircleFilled, PlusCircleFilled, EditFilled } from '@ant-design/icons';
import { fetchRateInitiated } from '../../store/modules/rate/actions';
import { clearErrSuccessMsgs, removeCurrencyInitiated } from '../../store/modules/currency/actions';
import { selectRate } from '../../store/modules/rate';
import { selectCurrency } from '../../store/modules/currency';
import { openError, openNotification } from '../../utils/libs';
import styles from './rate.module.css';
import TableSketelon from '../../components/TableSkeleton';

export default function Rate({ openEditCurrencyModal, openAddCurrencyModal }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { result: rateList, error, loading } = useSelector(selectRate);
  const currencyStore = useSelector(selectCurrency);

  const getRate = useCallback(() => {
    dispatch(fetchRateInitiated());
  }, [dispatch]);

  const clearCurrencyMsgs = useCallback(() => {
    dispatch(clearErrSuccessMsgs());
  }, [dispatch]);

  const removeCurrency = useCallback(
    (data) => {
      dispatch(removeCurrencyInitiated(data));
    },
    [dispatch],
  );

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getRate();
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [rateList, getRate]);

  useEffect(() => {
    error && openError('', JSON.stringify(error));
  }, [error]);

  useEffect(() => {
    if (currencyStore.error) {
      openError('', JSON.stringify(currencyStore?.error || ''));
      clearCurrencyMsgs();
    }
  }, [currencyStore?.error, clearCurrencyMsgs]);

  useEffect(() => {
    if (currencyStore.successMsg) {
      openNotification('topRight', currencyStore.successMsg, 'Successful');
      clearCurrencyMsgs();
    }
  }, [currencyStore?.successMsg, clearCurrencyMsgs]);

  useEffect(() => {
    Auth.currentSession()
      .then((res) => {})
      .catch((e) => {
        openError('Sign-in expired', 'please login again');
        history.push('/login');
      });
  }, [history]);

  const confirmDelete = (data) => async (e) => {
    removeCurrency(data);
  };

  const columns = [
    {
      title: () => {
        return (
          <div className={styles.contentSpace}>
            <span>Currency</span>

            <Button
              id="open-ac-modal"
              type="text"
              icon={<PlusCircleFilled />}
              onClick={() => openAddCurrencyModal()}
            ></Button>
          </div>
        );
      },
      dataIndex: 'currency',
      key: 'currency',
      render: (currency, record, index) => {
        return (
          <div className={`curreny-name ${styles.contentSpace}`} key={index}>
            <span>{currency}</span>
            <Popconfirm
              title="Are you sure to delete this currency?"
              onConfirm={confirmDelete({ currency })}
              onCancel={undefined}
              okText="Yes"
              cancelText="No"
              cancelButtonProps={{ className: 'confirm-no' }}
            >
              <Button type="text" icon={<MinusCircleFilled />}></Button>
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: 'Quote Id',
      dataIndex: 'quoteid',
      key: 'quoteid',
    },
    {
      title: 'Valid Until',
      dataIndex: 'valid_until',
      key: 'valid_until',
    },
    {
      title: 'NK Buy',
      dataIndex: 'sell',
      key: 'sell',
      render: (sell, record, index) => {
        return (
          <div key={index} className={styles.contentSpace}>
            <span>{sell}</span>
            <Tooltip title="Edit the pricing schema">
              <Button
                type="text"
                icon={<EditFilled />}
                onClick={() =>
                  openEditCurrencyModal(this, {
                    currency: record.currency,
                    type: 'buy',
                    rtype: 'sell',
                  })
                }
              ></Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'NK Sell',
      dataIndex: 'buy',
      key: 'buy',
      render: (buy, record, index) => {
        return (
          <div key={index} className={styles.contentSpace}>
            <span>{buy}</span>
            <Tooltip title="Edit the pricing schema">
              <Button
                type="text"
                icon={<EditFilled />}
                onClick={() =>
                  openEditCurrencyModal(this, {
                    currency: record.currency,
                    type: 'sell',
                    rtype: 'buy',
                  })
                }
              ></Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h1>Rate table</h1>
      {loading ? (
        <TableSketelon columns={columns} />
      ) : (
        <Table
          id={'rate-table'}
          columns={columns}
          dataSource={rateList}
          pagination={{ pageSize: 50 }}
          scroll={{ y: '70vh', x: 1000 }}
        />
      )}
    </div>
  );
}
