import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, notification, Tag } from 'antd';
import { Resizable } from 'react-resizable';

import { Auth } from 'aws-amplify';

import { CheckCircleOutlined, CloseCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import { getColumnSearchFilterProps } from '../../components/AntColumnSearchFilter';
import AntPagination from '../../components/AntPagination';

import {
  clearFetchedTransactionInfo,
  fetchTransactionInfoInitiated,
  transactionConfirmationInitiated,
  filterTransactionInitiated,
  getMoreTransactionInitiated,
  getPrevTransactionInitiated,
  setTransactionLimit,
  getTransactionInitiated,
  fetchPayeeInitiated,
} from '../../store/modules/transaction/actions';
import { selectTransaction, selectFilters, selectPayees } from '../../store/modules/transaction';
import { formatMoney, IsJsonString, openError } from '../../utils/libs';
import {
  CURRENCY,
  TRANSACTION_TYPE,
  BUY_SELL_ORDER_OBJECT,
  REMITTANCE_ORDER_OBJECT,
} from '../../utils/constants';
import { TransactionInfo } from '../../components/TransactionInfo';
import TableSketelon from '../../components/TableSkeleton';
import './transaction.css';

const COLUMN_WIDTH = 150;
const ResizableTitle = (props) => {
  const { onResize, width, onResizeStop, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      {...{ width, onResize, onResizeStop }}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default function Transaction(props) {
  const history = useHistory();
  const location = useLocation();
  const searchInput = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const transactionId = location.search ? queryParams.get('transactionId') : '';
  const transactionFilters = useSelector(selectFilters);
  const { data: payeeInfoData, fetchPayee } = useSelector(selectPayees);

  const [infoModalToggle, setInfoModalToggle] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState({});
  const [record, setRecord] = useState({});
  const [tabKey, setTabKey] = useState('1');

  const [columns, updateColumns] = useState([]);

  const columnNewWidth = useRef([]);
  const [persistWidth, setPersistWidth] = useState(false);

  const showModal = (record) => {
    let item = {
      ...record,
      balance_updated_1: record?.balance_updated_1?.toString(),
      balance_updated_2: record?.balance_updated_2?.toString(),
    };
    if (record.local_amount) item.local_amount = formatMoney(record.local_amount);
    if (record.foreign_amount) item.foreign_amount = formatMoney(record.foreign_amount);
    if (item.type === 'remittance' && item.recipient !== undefined) {
      if (IsJsonString(item.recipient)) {
        item.recipient = JSON.parse(item.recipient);
      } else {
        getPayee({ id: item.recipient });
      }
    }
    if (item.type === TRANSACTION_TYPE.buyfx.value || item.type === TRANSACTION_TYPE.sellfx.value) {
      item = Object.assign(BUY_SELL_ORDER_OBJECT, item);
    } else if (item.type === TRANSACTION_TYPE.remittance.value) {
      item = Object.assign(REMITTANCE_ORDER_OBJECT, item);
    }

    // Remove blank keys from the object
    item = Object.fromEntries(Object.entries(item).filter(([_, v]) => v != null));
    setTransactionInfo(item);
    setRecord(record);
    setTabKey('1');
    setInfoModalToggle(true);
  };

  const handleCancel = () => setInfoModalToggle(false);

  const dispatch = useDispatch();
  const {
    Items: transactionList,
    loading,
    paginationLoading,
    cursorCache = [],
    nextCursor,
    limit,
    fetchTransactionInfoSuccessResponse,
    transactionInfoLoading,
    useLedger,
  } = useSelector(selectTransaction);
  const successCallback = useCallback((record) => {
    if (record.transaction_status) {
      setInfoModalToggle(false);
    }
    openNotification('successful', {
      username: record.email,
      transaction_id: record.transaction_id,
      status: record.transaction_status,
    });
  }, []);

  const failureCallback = useCallback((payload) => {
    openError('Rejected', `${JSON.stringify(payload.data.messages, null, '\n')} `);
  }, []);

  const [username, setUsername] = useState('');
  useEffect(() => {
    Auth.currentSession()
      .then(() => {})
      .catch(() => {
        openError('Sign-in expired', 'please login again');
        history.push('/login');
      });
    Auth.currentUserInfo()
      .then((user) => {
        setUsername(user.attributes.email);
      })
      .catch(() => {});
  }, [history]);

  const openNotification = (placement, requestionInfo) => {
    notification.success({
      message: `Request ${JSON.stringify(placement, null, '\n')}`,
      description: `${JSON.stringify(requestionInfo, null, '\n')} `,
      placement,
    });
  };

  const fetchTransactions = useCallback(() => {
    dispatch(
      getTransactionInitiated({
        ...transactionFilters,
        per_page: limit,
      }),
    );
  }, [dispatch, limit, transactionFilters]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const transactionAction = useCallback(
    (record, status) => {
      dispatch(
        transactionConfirmationInitiated({
          successCallback,
          failureCallback,
          useLedger,
          transactionUserDetails: fetchTransactionInfoSuccessResponse?.data?.user,
          email: record.email,
          uid: record.uid,
          transaction_id: record.transaction_id,
          transaction_status: status,
          transaction_type: record.type,
          setTransactionInfo: setTransactionInfo,
          adminUser: username,
          transactionInfo: {
            ...transactionInfo,
            fee: record.type === 'remittance' ? record.fee : transactionInfo.fee,
          },
        }),
      );
    },
    [
      dispatch,
      successCallback,
      failureCallback,
      useLedger,
      fetchTransactionInfoSuccessResponse,
      transactionInfo,
      username,
    ],
  );

  const filterTransaction = useCallback(
    (filter) => {
      dispatch(filterTransactionInitiated({ ...filter }));
    },
    [dispatch],
  );

  const [checkedTransactionId, setCheckedTransactionId] = useState('');
  const handleFetchTransactionInfo = (record) => {
    dispatch(clearFetchedTransactionInfo());
    setCheckedTransactionId(record.transaction_id);
  };

  const getTransactionInfo = useCallback(() => {
    dispatch(fetchTransactionInfoInitiated({ transaction_id: checkedTransactionId }));
  }, [dispatch, checkedTransactionId]);

  useEffect(() => {
    if (checkedTransactionId !== '') {
      const timeOut = setTimeout(() => {
        getTransactionInfo();
      }, 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [checkedTransactionId, getTransactionInfo, fetchTransactionInfoSuccessResponse]);

  const setLimit = useCallback(
    (newLimit) => {
      dispatch(setTransactionLimit(newLimit));
    },
    [dispatch],
  );
  const getMoreTransaction = useCallback(() => {
    dispatch(
      getMoreTransactionInitiated({
        ...transactionFilters,
        per_page: limit,
        cursor: nextCursor,
      }),
    );
  }, [dispatch, nextCursor, limit, transactionFilters]);
  const getPrevTransaction = useCallback(() => {
    dispatch(
      getPrevTransactionInitiated({
        ...transactionFilters,
        per_page: limit,
        cursor: cursorCache[cursorCache.length - 2],
      }),
    );
  }, [dispatch, cursorCache, limit, transactionFilters]);

  const getPayee = useCallback(
    (params) => {
      dispatch(fetchPayeeInitiated(params));
    },
    [dispatch],
  );
  useEffect(() => {
    if (transactionInfo?.type === 'remittance' && fetchPayee !== undefined && !fetchPayee) {
      if (payeeInfoData) {
        transactionInfo.recipient = payeeInfoData;
      }
      setTransactionInfo(transactionInfo);
    }
  }, [transactionInfo, payeeInfoData, fetchPayee]);

  function calculateTimeSince(created) {
    let currentTime = Date.now();
    let createdTime = Date.parse(created);
    let secPassed = Math.floor((currentTime - createdTime) / 1000);
    if (secPassed <= 60) {
      return '< 1 min ago';
    } else if (secPassed > 60 && secPassed <= 3600) {
      return Math.floor(secPassed / 60) === 1
        ? Math.floor(secPassed / 60).toString() + ' min ago'
        : Math.floor(secPassed / 60).toString() + ' mins ago';
    } else if (secPassed > 3600) {
      let message;
      if (Math.floor(secPassed / 3600) === 1) {
        message = Math.floor(secPassed / 3600).toString() + ' hour ago';
      } else if (Math.floor(secPassed / 3600) < 24) {
        message = Math.floor(secPassed / 3600).toString() + ' hours ago';
      } else {
        message =
          (Math.floor(secPassed / 3600) / 24).toFixed(0) +
          ' days, ' +
          (Math.floor(secPassed / 3600) % 24) +
          ' hours ago';
      }
      return message;
    }
  }

  useEffect(() => {
    if (persistWidth) {
      if (
        typeof columnNewWidth.current[0] === 'string' &&
        typeof columnNewWidth.current[1] === 'number'
      ) {
        const txColumnsWidths = localStorage.getItem('txColumnsWidths');
        if (!txColumnsWidths) {
          localStorage.setItem(
            'txColumnsWidths',
            JSON.stringify({
              [columnNewWidth.current[0]]: columnNewWidth.current[1],
            }),
          );
        } else {
          const prevWidths = JSON.parse(txColumnsWidths);
          localStorage.setItem(
            'txColumnsWidths',
            JSON.stringify({
              ...prevWidths,
              [columnNewWidth.current[0]]: columnNewWidth.current[1],
            }),
          );
        }
        columnNewWidth.current = [];
      }
      setPersistWidth(false);
    }
  }, [persistWidth]);

  useEffect(() => {
    const handleResize =
      (index, key) =>
      (e, { size }) => {
        updateColumns((prevCol) => {
          const nextColumns = [...prevCol];
          columnNewWidth.current = [nextColumns[index].key, size.width];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          return nextColumns;
        });
      };
    const onResizeStop = () => {
      setPersistWidth(true);
    };
    if (localStorage.getItem('txColumnsWidth')) localStorage.removeItem('txColumnsWidth');
    const txColumnsWidths = localStorage.getItem('txColumnsWidths');
    const prevWidths = txColumnsWidths ? JSON.parse(txColumnsWidths) : {};
    const initCol = [
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        filters: [
          { text: 'pending', value: 'pending' },
          { text: 'confirmed', value: 'confirmed' },
          { text: 'rejected', value: 'rejected' },
        ],
        defaultFilteredValue: transactionFilters?.status || [],
        render: (status) => <span>{return_tag(status)}</span>,
        width: prevWidths['status'] || COLUMN_WIDTH,
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: prevWidths['type'] || COLUMN_WIDTH,
        render: (item) => <>{TRANSACTION_TYPE[item].name}</>,
        ellipsis: true,
        filters: Object.keys(TRANSACTION_TYPE).map((key) => ({ text: key, value: key })),
        defaultFilteredValue: transactionFilters?.type || [],
      },
      {
        title: 'Time since',
        dataIndex: 'created',
        key: 'timeago',
        sortType: 'number',
        sorter: (a, b) => Date.parse(b.created) - Date.parse(a.created),
        sortDirections: ['descend', 'ascend'],
        render: (item) => <>{calculateTimeSince(item)}</>,
        width: prevWidths['timeago'] || COLUMN_WIDTH + 20,
        ellipsis: true,
      },
      {
        title: 'Username',
        dataIndex: 'email',
        key: 'username',
        width: prevWidths['username'] || COLUMN_WIDTH + 50,
        ellipsis: true,
        defaultFilteredValue: transactionFilters?.username || [],
        ...getColumnSearchFilterProps('username', searchInput),
      },
      {
        title: 'HKD Value',
        dataIndex: 'local_amount',
        key: 'local_amount',
        sortType: 'number',
        sorter: (a, b) => a.local_amount - b.local_amount,
        sortDirections: ['descend', 'ascend'],
        width: prevWidths['local_amount'] || COLUMN_WIDTH - 30,
        ellipsis: true,
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        width: prevWidths['rate'] || COLUMN_WIDTH - 50,
        ellipsis: true,
      },
      {
        title: 'FX',
        dataIndex: 'foreign_currency',
        key: 'foreign_currency',
        width: prevWidths['foreign_currency'] || COLUMN_WIDTH / 2,
        ellipsis: true,
        filters: CURRENCY.filter((currency) => currency !== 'HKD').map((currency) => ({
          text: currency,
          value: currency,
        })),
        defaultFilteredValue: transactionFilters?.foreign_currency || [],
      },
      {
        title: 'FX Amount',
        dataIndex: 'foreign_amount',
        key: 'foreign_amount',
        width: prevWidths['foreign_amount'] || COLUMN_WIDTH,
        ellipsis: true,
        render: (item) => <>{item && formatMoney(item)}</>,
      },
      {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        sortType: 'number',
        sorter: (a, b) => Date.parse(a.created) - Date.parse(b.created),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'descend',
        width: prevWidths['created'] || COLUMN_WIDTH,
        ellipsis: true,
      },
      {
        title: 'Last Updated',
        dataIndex: 'updated',
        key: 'last_updated',
        sortType: 'number',
        sorter: (a, b) => Date.parse(a.updated) - Date.parse(b.updated),
        sortDirections: ['descend', 'ascend'],
        width: prevWidths['last_updated'] || COLUMN_WIDTH,
        ellipsis: true,
      },
      {
        title: 'Transaction ID',
        dataIndex: 'transaction_id',
        key: 'transaction_id',
        width: prevWidths['transaction_id'] || COLUMN_WIDTH,
        ellipsis: true,
      },
    ].map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize(index),
        onResizeStop,
      }),
    }));
    updateColumns([...initCol]);
  }, [
    transactionFilters?.foreign_currency,
    transactionFilters?.status,
    transactionFilters?.type,
    transactionFilters?.username,
  ]);

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const tableLoading = loading || paginationLoading;

  return (
    <div>
      <h1>Transaction table</h1>
      {loading ? (
        <TableSketelon columns={columns} />
      ) : (
        <Table
          onRow={(row) => ({
            onClick: () => showModal(row),
          })}
          {...{ components, columns }}
          dataSource={transactionList}
          pagination={false}
          id="table-resizable-column"
          scroll={{ y: '70vh', x: 'min-content' }}
          rowKey={(record) => record.transaction_id}
          onChange={(_, filters) => {
            filterTransaction(
              Object.keys(filters)
                .filter((item) => filters[item])
                .reduce((acc, item) => ({ ...acc, [item]: filters[item] }), {}),
            );
          }}
          {...(transactionId && { defaultExpandedRowKeys: [transactionId] })}
        />
      )}
      <AntPagination
        pageSize={limit}
        isLoading={tableLoading}
        onNextBtnClick={getMoreTransaction}
        onPrevBtnClick={getPrevTransaction}
        onChange={(_, size) => setLimit(size)}
        disableNext={!nextCursor}
        disablePrev={cursorCache.length <= 1}
      />
      <TransactionInfo
        visible={infoModalToggle}
        {...{
          record,
          transactionInfo,
          fetchTransactionInfoSuccessResponse,
          loading,
          transactionAction,
          transactionInfoLoading,
          handleFetchTransactionInfo,
          handleCancel,
          tabKey,
          setTabKey,
        }}
      />
    </div>
  );
}

function return_tag(status) {
  let color = status; // pending, confirmed, rejected
  let icon;
  if (color === 'confirmed') {
    color = 'green';
    icon = <CheckCircleOutlined />;
  } else if (color === 'pending') {
    color = 'geekblue';
    icon = <ClockCircleOutlined />;
  } else if (color === 'rejected') {
    color = 'red';
    icon = <CloseCircleOutlined />;
  } else {
    color = 'default';
  }

  return (
    <Tag style={{ whiteSpace: 'break-spaces' }} color={color} icon={icon} key={status}>
      {status.toUpperCase()}
    </Tag>
  );
}
