export const FETCH_BALANCE_INITIATED = 'FETCH_BALANCE_INITIATED';
export const FETCH_BALANCE_SUCCEEDED = 'FETCH_BALANCE_SUCCEEDED';
export const FETCH_BALANCE_FAILED = 'FETCH_BALANCE_FAILED';

export const FILTER_BALANCE_INITIATED = 'FILTER_BALANCE_INITIATED';

export const GET_MORE_BALANCE_INITIATED = 'GET_MORE_BALANCE_INITIATED';
export const GET_MORE_BALANCE_SUCCEEDED = 'GET_MORE_BALANCE_SUCCEEDED';
export const GET_MORE_BALANCE_FAILED = 'GET_MORE_BALANCE_FAILED';

export const GET_PREVIOUS_BALANCE_INITIATED = 'GET_PREVIOUS_BALANCE_INITIATED';
export const GET_PREVIOUS_BALANCE_SUCCEEDED = 'GET_PREVIOUS_BALANCE_SUCCEEDED';
export const GET_PREVIOUS_BALANCE_FAILED = 'GET_PREVIOUS_BALANCE_FAILED';

export const SET_PAGINATION_LIMIT = 'SET_PAGINATION_LIMIT';
