import React from 'react';
import { Switch } from 'antd';
import styles from './index.module.css';
import { useDispatch } from 'react-redux';
import { setUseLedgerOption } from '../store/modules/transaction/actions';
import { NKDB_KEY } from '../utils/constants';

const getLedgerStateFromLocalStorage = () => {
  return localStorage.getItem(NKDB_KEY) === '1';
};

export function AddToNKDB() {
  const dispatch = useDispatch();

  const [shouldUseLedger, setShouldUseLedger] = React.useState(getLedgerStateFromLocalStorage);

  const toggleUseDB = React.useCallback(() => {
    setShouldUseLedger((prev) => !prev);
  }, []);

  // save to local storage
  React.useEffect(() => {
    localStorage.setItem(NKDB_KEY, shouldUseLedger ? 1 : 0);
  }, [shouldUseLedger]);

  React.useEffect(() => {
    dispatch(setUseLedgerOption(shouldUseLedger));
  }, [shouldUseLedger, dispatch]);

  return (
    <div className={styles.mb_10}>
      <span className={styles.label}>Post trades to NK DB:</span>
      <Switch
        className={shouldUseLedger ? styles.bg_primary : styles.bg_gray}
        checkedChildren="On"
        unCheckedChildren="Off"
        defaultChecked
        checked={shouldUseLedger}
        onChange={toggleUseDB}
      />
    </div>
  );
}
