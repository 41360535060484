import { Skeleton, Table } from 'antd';

const TableSketelon = ({ columns }) => {
  return (
    <Table
      dataSource={[...Array(50)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return <Skeleton key={column.key} title paragraph={false} />;
          },
        };
      })}
    />
  );
};

export default TableSketelon;
