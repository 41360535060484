export const FETCH_TRANSACTION_INITIATED = 'FETCH_TRANSACTION_INITIATED';
export const FETCH_TRANSACTION_SUCCEEDED = 'FETCH_TRANSACTION_SUCCEEDED';
export const FETCH_TRANSACTION_FAILED = 'FETCH_TRANSACTION_FAILED';

export const TRANSACTION_CONFIRMATION_INITIATED = 'TRANSACTION_CONFIRMATION_INITIATED';
export const TRANSACTION_CONFIRMATION_SUCCEEDED = 'TRANSACTION_CONFIRMATION_SUCCEEDED';
export const TRANSACTION_CONFIRMATION_FAILED = 'TRANSACTION_CONFIRMATION_FAILED';

export const ADD_TRANSACTION_INITIATED = 'ADD_TRANSACTION_INITIATED';
export const ADD_TRANSACTION_SUCCEEDED = 'ADD_TRANSACTION_SUCCEEDED';
export const ADD_TRANSACTION_FAILED = 'ADD_TRANSACTION_FAILED';

export const FETCH_TRANSACTION_INFO_INITIATED = 'FETCH_TRANSACTION_INFO_INITIATED';
export const FETCH_TRANSACTION_INFO_SUCCEEDED = 'FETCH_TRANSACTION_INFO_SUCCEEDED';
export const FETCH_TRANSACTION_INFO_FAILED = 'FETCH_TRANSACTION_INFO_FAILED';
export const CLEAR_FETCH_TRANSACTION_INFO = 'CLEAR_FETCH_TRANSACTION_INFO';

export const FETCH_USER_INFO_INITIATED = 'FETCH_USER_INFO_INITIATED';
export const FETCH_USER_INFO_SUCCEEDED = 'FETCH_USER_INFO_SUCCEEDED';
export const FETCH_USER_INFO_FAILED = 'FETCH_USER_INFO_FAILED';

export const CLEAR_USER_INFO_ERROR_SUCCESS = 'CLEAR_USER_INFO_ERROR_SUCCESS';
export const SET_USE_LEDGER_OPTION = 'SET_USE_LEDGER_OPTION';

export const FILTER_TRANSACTION_INITIATED = 'FILTER_TRANSACTION_INITIATED';

export const GET_MORE_TRANSACTION_INITIATED = 'GET_MORE_TRANSACTION_INITIATED';
export const GET_MORE_TRANSACTION_SUCCEEDED = 'GET_MORE_TRANSACTION_SUCCEEDED';
export const GET_MORE_TRANSACTION_FAILED = 'GET_MORE_TRANSACTION_FAILED';

export const GET_PREVIOUS_TRANSACTION_INITIATED = 'GET_PREVIOUS_TRANSACTION_INITIATED';
export const GET_PREVIOUS_TRANSACTION_SUCCEEDED = 'GET_PREVIOUS_TRANSACTION_SUCCEEDED';
export const GET_PREVIOUS_TRANSACTION_FAILED = 'GET_PREVIOUS_TRANSACTION_FAILED';

export const SET_TRANSACTION_LIMIT = 'SET_TRANSACTION_LIMIT';

export const GET_TRANSACTION_INITIATED = 'GET_TRANSACTION_INITIATED';
export const GET_TRANSACTION_SUCCEEDED = 'GET_TRANSACTION_SUCCEEDED';
export const GET_TRANSACTION_FAILED = 'GET_TRANSACTION_FAILED';

export const GET_PAYEE_INFO_INITIATED = 'GET_PAYEE_INFO_INITIATED';
export const GET_PAYEE_INFO_SUCCEEDED = 'GET_PAYEE_INFO_SUCCEEDED';
export const GET_PAYEE_INFO_FAILED = 'GET_PAYEE_INFO_FAILED';
