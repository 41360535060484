import request from '../../../utils/request';
import config from '../../../aws-exports';
const https = require('https');

const getTransactionList = async (data) => {
  const response = await request.get(`/v6/nk/query/transaction/list?${new URLSearchParams(data)}`);
  let items = [];
  let nextCursor = '';

  if (response.data && response.data.result) items = response.data.result;
  if (response.data && response.data.nextCursor) nextCursor = response.data.nextCursor;

  return {
    ...response,
    transaction: {
      Items: items,
      nextCursor,
    },
  };
};

const confirmTransaction = async (data) =>
  await request
    .post('/v5/nk/confirm', { data, getResponse: true })
    .then(({ data, response: { status } }) => ({ ...data, status }));

const addTransaction = async (data) => await request.post('/v7/nk/transaction', { data });

const addTransactionOffline = async (data) => await request.post('/v6/nk/notify ', { data });

const getUserInfo = async (params) => await request.get('/v7/nk/query/user', { params });

const getTransactionInfo = async (data) =>
  await request.get(`/v5/nk/query/transaction?transaction_id=${data.transaction_id}`);

const getPayeeInfo = async (params) => await request.get('/v6/nk/query/payee', { params });

const orderTransaction = async (data) => {
  const agent = new https.Agent({
    rejectUnauthorized: false,
  });
  await fetch(`${config.nkApiURL}?x-token=${config.nkApiToken}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
    agent,
  });
};

const actions = {
  getTransactionList,
  confirmTransaction,
  addTransaction,
  getUserInfo,
  getTransactionInfo,
  orderTransaction,
  addTransactionOffline,
  getPayeeInfo,
};

export default actions;
