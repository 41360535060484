import { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import styles from './index.module.css';

const initialTipMsg = 'copy error message';

const CopyToClipboard = ({ children, classname, copyText }) => {
  const [tip, setTip] = useState(initialTipMsg);
  const handleClipCopy = () => {
    navigator.clipboard.writeText(copyText);
    setTip('Error copied to clipboard!');
  };

  useEffect(() => {
    let timeout;

    if (tip !== initialTipMsg) {
      timeout = setTimeout(() => setTip(initialTipMsg), 2000);
    }

    return () => clearTimeout(timeout);
  }, [tip]);

  return (
    <Tooltip title={tip}>
      <button className={classname || styles.clipBoardBtn} onClick={handleClipCopy}>
        {children}
      </button>
    </Tooltip>
  );
};

export default CopyToClipboard;
