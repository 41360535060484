import { Layout, Menu } from 'antd';
import {
  PlusOutlined,
  LogoutOutlined,
  TransactionOutlined,
  FundOutlined,
  WalletOutlined,
  TeamOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';

import styles from './index.module.css';
import { fetchSignOutInitiated } from '../store/modules/auth/actions';
import { withProvider } from '../store';
import { openError } from '../utils/libs';
import { useGetTransaction } from '../hooks/useGetTransaction';

const { Header, Content, Footer, Sider } = Layout;

const routes = {
  transaction: '/home/transaction',
  balance: '/home/balance',
  rate: '/home/rate',
  add: '/home/transaction/add',
  payee: '/home/payee',
  settings: '/home/settings',
};

function BasicLayout(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    Auth.currentSession()
      .then((res) => {})
      .catch((e) => {
        const redirectRoute =
          location.pathname === '/reset-password' ? '/reset-password' : '/login';

        history.push(redirectRoute);
      });
  }, [history, location.pathname]);

  async function signOut() {
    try {
      await Auth.signOut({ global: true });
      dispatch(fetchSignOutInitiated());
      history.push('/login');
    } catch (error) {
      openError(error, ' Please login first.');
    }
  }

  const isPathProtected = (pathname) => ['/login', '/reset-password'].includes(pathname);

  useGetTransaction();

  return (
    <>
      {!isPathProtected(location.pathname) ? (
        <Layout>
          <Sider
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              left: 0,
            }}
          >
            <div className={styles.logo} />
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[routes.transaction]}
              selectedKeys={[location.pathname]}
            >
              <Menu.Item key={routes.balance} icon={<WalletOutlined />}>
                <Link to={routes.balance}>Balance</Link>
              </Menu.Item>
              <Menu.Item key={routes.transaction} icon={<TransactionOutlined />}>
                <Link to={routes.transaction}>Transaction</Link>
              </Menu.Item>
              <Menu.Item key={routes.rate} icon={<FundOutlined />}>
                <Link to={routes.rate}>Rate</Link>
              </Menu.Item>
              <Menu.Item key={routes.payee} icon={<TeamOutlined />}>
                <Link to={routes.payee}>Payee</Link>
              </Menu.Item>
              <Menu.Item key={routes.add} icon={<PlusOutlined />}>
                <Link to={routes.add}>Add Transaction</Link>
              </Menu.Item>
              <Menu.Item key={routes.settings} icon={<SettingOutlined />}>
                <Link to={routes.settings}>Settings</Link>
              </Menu.Item>
              <Menu.Item key={'logout'} icon={<LogoutOutlined />}>
                <span
                  onClick={() => {
                    signOut();
                  }}
                >
                  Logout
                </span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: 200, minHeight: '100vh' }}>
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
                height: 'auto',
                lineHeight: 'unset',
              }}
            >
              <h1 className={styles.header_title}>Fin Admin Panel</h1>
            </Header>
            <Content style={{ overflow: 'initial', paddingRight: 30, paddingLeft: 30 }}>
              <div className="site-layout-background" style={{ textAlign: 'center' }}>
                {props.children}
              </div>
            </Content>
            <Footer
              style={{
                textAlign: 'center',
                justifyContent: 'flex-end',
              }}
            >
              © 2021 - Fin is made with 🖤 by Pecutus Technologies Ltd
            </Footer>
          </Layout>
        </Layout>
      ) : (
        <Layout>
          <Layout className="site-layout" style={{ height: '100vh' }}>
            <Header style={{ height: 'auto', padding: 20 }}>
              <div style={{ textAlign: 'center' }}>
                <div className={styles.logo} />
                <h1 style={{ color: '#e683ff' }}>FinAdmin</h1>
              </div>
            </Header>
            <Content style={{ overflow: 'initial' }}>
              <div
                className="site-layout-background"
                style={{
                  textAlign: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                }}
              >
                {props.children}
              </div>
            </Content>
            <Footer
              style={{
                textAlign: 'center',
                justifyContent: 'flex-end',
              }}
            >
              © 2021 - Fin is made with 🖤 by Pecutus Technologies Ltd
            </Footer>
          </Layout>
        </Layout>
      )}
    </>
  );
}

export default withProvider(BasicLayout);
