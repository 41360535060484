import {
  FETCH_TRANSACTION_INITIATED,
  FETCH_TRANSACTION_SUCCEEDED,
  FETCH_TRANSACTION_FAILED,
  TRANSACTION_CONFIRMATION_FAILED,
  TRANSACTION_CONFIRMATION_INITIATED,
  TRANSACTION_CONFIRMATION_SUCCEEDED,
  ADD_TRANSACTION_INITIATED,
  ADD_TRANSACTION_SUCCEEDED,
  ADD_TRANSACTION_FAILED,
  FETCH_USER_INFO_INITIATED,
  FETCH_USER_INFO_SUCCEEDED,
  FETCH_USER_INFO_FAILED,
  CLEAR_USER_INFO_ERROR_SUCCESS,
  FETCH_TRANSACTION_INFO_INITIATED,
  FETCH_TRANSACTION_INFO_SUCCEEDED,
  FETCH_TRANSACTION_INFO_FAILED,
  CLEAR_FETCH_TRANSACTION_INFO,
  FILTER_TRANSACTION_INITIATED,
  SET_USE_LEDGER_OPTION,
  GET_MORE_TRANSACTION_INITIATED,
  GET_MORE_TRANSACTION_SUCCEEDED,
  GET_MORE_TRANSACTION_FAILED,
  GET_PREVIOUS_TRANSACTION_INITIATED,
  GET_PREVIOUS_TRANSACTION_SUCCEEDED,
  GET_PREVIOUS_TRANSACTION_FAILED,
  SET_TRANSACTION_LIMIT,
  GET_TRANSACTION_INITIATED,
  GET_TRANSACTION_SUCCEEDED,
  GET_TRANSACTION_FAILED,
  GET_PAYEE_INFO_INITIATED,
  GET_PAYEE_INFO_SUCCEEDED,
  GET_PAYEE_INFO_FAILED,
} from './types';

export const fetchTransactionInitiated = (data) => ({
  type: FETCH_TRANSACTION_INITIATED,
  payload: data,
});

export const fetchTransactionSucceeded = (data) => ({
  type: FETCH_TRANSACTION_SUCCEEDED,
  payload: data,
});

export const fetchTransactionFailed = (data) => ({
  type: FETCH_TRANSACTION_FAILED,
  payload: data,
});

export const transactionConfirmationInitiated = (data) => ({
  type: TRANSACTION_CONFIRMATION_INITIATED,
  payload: data,
});

export const transactionConfirmationSucceeded = (data) => ({
  type: TRANSACTION_CONFIRMATION_SUCCEEDED,
  payload: data,
});

export const transactionConfirmationFailed = (data) => ({
  type: TRANSACTION_CONFIRMATION_FAILED,
  payload: data,
});

export const addTransactionInitiated = (data) => ({
  type: ADD_TRANSACTION_INITIATED,
  payload: data,
});

export const addTransactionSucceeded = (data) => ({
  type: ADD_TRANSACTION_SUCCEEDED,
  payload: data,
});

export const addTransactionFailed = (data) => ({
  type: ADD_TRANSACTION_FAILED,
  payload: data,
});

export const fetchUserInfoInitiated = (data) => ({
  type: FETCH_USER_INFO_INITIATED,
  payload: data,
});

export const fetchUserInfoSucceeded = (data) => ({
  type: FETCH_USER_INFO_SUCCEEDED,
  payload: data,
});

export const fetchUserInfoFailed = (data) => ({
  type: FETCH_USER_INFO_FAILED,
  payload: data,
});

export const clearUserInfoErrorSuccess = () => ({
  type: CLEAR_USER_INFO_ERROR_SUCCESS,
});

export const fetchTransactionInfoInitiated = (data) => ({
  type: FETCH_TRANSACTION_INFO_INITIATED,
  payload: data,
});

export const fetchTransactionInfoSucceeded = (data) => ({
  type: FETCH_TRANSACTION_INFO_SUCCEEDED,
  payload: data,
});

export const fetchTransactionInfoFailed = (data) => ({
  type: FETCH_TRANSACTION_INFO_FAILED,
  payload: data,
});

export const clearFetchedTransactionInfo = () => ({
  type: CLEAR_FETCH_TRANSACTION_INFO,
});

export const filterTransactionInitiated = (payload) => ({
  type: FILTER_TRANSACTION_INITIATED,
  payload,
});

export const getMoreTransactionInitiated = (payload) => ({
  type: GET_MORE_TRANSACTION_INITIATED,
  payload,
});

export const getMoreTransactionSucceeded = (payload) => ({
  type: GET_MORE_TRANSACTION_SUCCEEDED,
  payload,
});

export const getMoreTransactionFailed = (payload) => ({
  type: GET_MORE_TRANSACTION_FAILED,
  payload,
});

export const getPrevTransactionInitiated = (payload) => ({
  type: GET_PREVIOUS_TRANSACTION_INITIATED,
  payload,
});

export const getPrevTransactionSucceeded = (payload) => ({
  type: GET_PREVIOUS_TRANSACTION_SUCCEEDED,
  payload,
});

export const getPrevTransactionFailed = (payload) => ({
  type: GET_PREVIOUS_TRANSACTION_FAILED,
  payload,
});

export const setTransactionLimit = (payload) => ({
  type: SET_TRANSACTION_LIMIT,
  payload,
});

export const getTransactionInitiated = (payload) => ({
  type: GET_TRANSACTION_INITIATED,
  payload,
});

export const getTransactionSucceeded = (payload) => ({
  type: GET_TRANSACTION_SUCCEEDED,
  payload,
});

export const getTransactionFailed = (payload) => ({
  type: GET_TRANSACTION_FAILED,
  payload,
});

export const fetchPayeeInitiated = (payload) => ({
  type: GET_PAYEE_INFO_INITIATED,
  payload,
});

export const fetchPayeeSucceeded = (payload) => ({
  type: GET_PAYEE_INFO_SUCCEEDED,
  payload,
});

export const fetchPayeeFailed = (payload) => ({
  type: GET_PAYEE_INFO_FAILED,
  payload,
});

export const setUseLedgerOption = (data) => ({
  type: SET_USE_LEDGER_OPTION,
  payload: data,
});
