import React, { useState, Fragment, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { openError } from '../../utils/libs';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Table, Button, Popconfirm } from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import AddPayeeModal from '../../components/AddPayeeModal';
import { openNotification } from '../../utils/libs';
import { getColumnSearchFilterProps } from '../../components/AntColumnSearchFilter';
import AntPagination from '../../components/AntPagination';
import {
  getMorePayeeDataInitiated,
  getPrevPayeeDataInitated,
  setPaginationlimit,
  getUserPayeeInitiated,
  fetchPayee,
  deletePayee,
  resetPayee,
} from '../../store/modules/payee/actions';
import { selectPayee } from '../../store/modules/payee';
import TableSketelon from '../../components/TableSkeleton';

const Payee = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchInput = useRef(null);

  const [payeeState, setPayeeState] = useState({ isModalVisible: false });
  const { loading, nextCursor, limit, cursorCache, users, error, payeeDeleted, filters } =
    useSelector(selectPayee);

  const fetchPayees = useCallback(() => {
    dispatch(
      fetchPayee({
        per_page: limit,
      }),
    );
  }, [dispatch, limit]);

  const cleanupPayee = useCallback(() => {
    dispatch(resetPayee());
  }, [dispatch]);

  const deletePayeeData = useCallback(
    (data) => {
      dispatch(deletePayee(data));
    },
    [dispatch],
  );

  const handleSearch = useCallback(
    (email) => {
      dispatch(getUserPayeeInitiated({ email }));
    },
    [dispatch],
  );

  const handleModalCancel = useCallback(() => {
    setPayeeState((prevState) => ({
      ...prevState,
      isModalVisible: false,
    }));
  }, []);

  const getMorePayeeData = useCallback(() => {
    dispatch(
      getMorePayeeDataInitiated({
        per_page: limit,
        cursor: nextCursor,
      }),
    );
  }, [dispatch, limit, nextCursor]);

  const getPrevPayeeData = useCallback(() => {
    dispatch(
      getPrevPayeeDataInitated({ per_page: limit, cursor: cursorCache[cursorCache.length - 2] }),
    );
  }, [cursorCache, dispatch, limit]);

  const setLimit = useCallback(
    (newLimit) => {
      dispatch(setPaginationlimit(newLimit));
    },
    [dispatch],
  );

  const openModalStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  const handleTableChange = (_, filterChange, _2, changeInfo) => {
    if (changeInfo.action === 'filter') {
      const emailColumn = filterChange['user_email'];
      const searchValue = emailColumn ? emailColumn[0] : '';

      !(filters.email === searchValue) && handleSearch(searchValue);
    }
  };

  useEffect(() => {
    Auth.currentSession().catch(() => {
      openError('Sign-in expired', 'please login again');
      history.push('/login');
    });

    return cleanupPayee;
  }, [cleanupPayee, history]);

  useEffect(() => {
    fetchPayees();
  }, [fetchPayees]);

  useEffect(() => {
    error && openError('', JSON.stringify(error));
  }, [error]);

  useEffect(() => {
    payeeDeleted && openNotification('topRight', 'Succesfully deleted Payee data', 'Succesful');
  }, [payeeDeleted]);

  const columns = [
    {
      title: () => {
        return (
          <div style={openModalStyle}>
            <span>User Email</span>
            <Button
              id="open-ac-modal"
              type="text"
              icon={<PlusCircleFilled />}
              onClick={() =>
                setPayeeState((prevState) => ({
                  ...prevState,
                  isModalVisible: true,
                }))
              }
            ></Button>
            <AddPayeeModal
              isModalVisible={payeeState.isModalVisible}
              handleCancel={handleModalCancel}
            />
          </div>
        );
      },
      dataIndex: 'user_email',
      key: 'user_email',
      width: '17%',
      defaultFilteredValue: filters.email ? [filters.email] : [],
      ...getColumnSearchFilterProps('user_email', searchInput, 'search'),
      render: (user_email, record, index) => {
        return (
          <div style={openModalStyle} key={index}>
            <span>{user_email}</span>
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => deletePayeeData(record.id)}
              onCancel={undefined}
              okText="Yes"
              cancelText="No"
              cancelButtonProps={{ className: 'confirm-no' }}
            >
              <Button type="text" icon={<MinusCircleFilled />}></Button>
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: 'Nickname',
      dataIndex: 'nickname',
      key: 'nickname',
      sorter: (a, b) => a?.nickname?.localeCompare(b?.nickname),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'ID Number',
      dataIndex: 'id_number',
      key: 'id_number',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '17%',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Bank Account',
      dataIndex: 'bank_account',
      key: 'bank_account',
    },
    {
      title: 'Swift Code',
      dataIndex: 'swift_code',
      key: 'swift_code',
    },
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
      key: 'bank_name',
      sorter: (a, b) => a.bank_name.localeCompare(b.bank_name),
      sortDirections: ['descend', 'ascend'],
    },
  ];

  return (
    <div>
      <h1>Payee management table</h1>
      <Fragment>
        {loading ? (
          <TableSketelon columns={columns} />
        ) : (
          <Table
            columns={columns}
            dataSource={users}
            key={users.is}
            loading={loading}
            rowKey={(record) => record.id}
            pagination={false}
            scroll={{ y: '70vh', x: 1700 }}
            onChange={handleTableChange}
          />
        )}
        <AntPagination
          isLoading={loading}
          pageSize={limit}
          onChange={(_, size) => setLimit(size)}
          onNextBtnClick={getMorePayeeData}
          onPrevBtnClick={getPrevPayeeData}
          disableNext={!nextCursor}
          disablePrev={cursorCache?.length <= 1}
        />
      </Fragment>
    </div>
  );
};

export default React.memo(Payee);
