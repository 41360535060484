export const CLEAR_ERR_SUCCESS_MSGS = 'CLEAR_ERR_SUCCESS_MSGS';
export const FETCH_CURRENCY_INITIATED = 'FETCH_CURRENCY_INITIATED';
export const FETCH_CURRENCY_SUCCEEDED = 'FETCH_CURRENCY_SUCCEEDED';
export const FETCH_CURRENCY_FAILED = 'FETCH_CURRENCY_FAILED';

export const UPDATE_CURRENCY_INITIATED = 'UPDATE_CURRENCY_INITIATED';
export const UPDATE_CURRENCY_SUCCEEDED = 'UPDATE_CURRENCY_SUCCEEDED';
export const UPDATE_CURRENCY_FAILED = 'UPDATE_CURRENCY_FAILED';

export const ADD_CURRENCY_INITIATED = 'ADD_CURRENCY_INITIATED';
export const ADD_CURRENCY_SUCCEEDED = 'ADD_CURRENCY_SUCCEEDED';
export const ADD_CURRENCY_FAILED = 'ADD_CURRENCY_FAILED';

export const REMOVE_CURRENCY_INITIATED = 'REMOVE_CURRENCY_INITIATED';
export const REMOVE_CURRENCY_SUCCEEDED = 'REMOVE_CURRENCY_SUCCEEDED';
export const REMOVE_CURRENCY_FAILED = 'REMOVE_CURRENCY_FAILED';
