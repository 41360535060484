import React from 'react';
import TradeAlerts from '../../components/TradeAlerts';
import { AddToNKDB } from '../../components/AddToNKDB';
import HaltTrade from '../../components/HaltTrade';
import styles from './settings.module.css';

const Settings = () => {
  return (
    <div className={styles.content}>
      <h1>Settings</h1>
      <div className={styles.switchcontainer}>
        <TradeAlerts />
      </div>
      <div className={styles.switchcontainer}>
        <AddToNKDB />
      </div>
      <div className={styles.switchcontainer}>
        <HaltTrade />
      </div>
    </div>
  );
};

export default Settings;
