import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Rate from './rate';
import { CurrencyModalForm } from '../../components/Currency';
import { selectCurrency } from '../../store/modules/currency';

export default function RateWrapper() {
  const currencyStore = useSelector(selectCurrency);

  const [isECModalVisible, setIsECModalVisible] = useState(false);
  const [isACModalVisible, setIsACModalVisible] = useState(false);

  useEffect(() => {
    if (currencyStore.successMsg) {
      setIsACModalVisible(false);
      setIsECModalVisible(false);
    }
  }, [currencyStore?.successMsg]);

  const query = useRef({});
  const openEditCurrencyModal = (e, _query = {}) => {
    setIsECModalVisible(true);
    query.current = _query;
  };
  const openAddCurrencyModal = (e) => {
    setIsACModalVisible(true);
  };
  return (
    <div>
      {isECModalVisible ? (
        <CurrencyModalForm
          type="edit"
          title="Edit Currency Pricing Schema"
          visible={isECModalVisible}
          setIsVisible={setIsECModalVisible}
          query={query.current}
        />
      ) : isACModalVisible ? (
        <CurrencyModalForm
          type="add"
          title="Add Currency"
          visible={isACModalVisible}
          setIsVisible={setIsACModalVisible}
        />
      ) : (
        ''
      )}
      <Rate
        openEditCurrencyModal={openEditCurrencyModal}
        openAddCurrencyModal={openAddCurrencyModal}
      />
    </div>
  );
}
