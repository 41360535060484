import * as TYPES from './types';

export const clearErrSuccessMsgs = () => ({
  type: TYPES.CLEAR_ERR_SUCCESS_MSGS,
});

export const fetchCurrencyInitiated = (data) => ({
  type: TYPES.FETCH_CURRENCY_INITIATED,
  payload: data,
});

export const fetchCurrencySucceeded = (data) => ({
  type: TYPES.FETCH_CURRENCY_SUCCEEDED,
  payload: data,
});

export const fetchCurrencyFailed = (data) => ({
  type: TYPES.FETCH_CURRENCY_FAILED,
  payload: data,
});

export const updateCurrencyInitiated = (data) => ({
  type: TYPES.UPDATE_CURRENCY_INITIATED,
  payload: data,
});

export const updateCurrencySucceeded = (data) => ({
  type: TYPES.UPDATE_CURRENCY_SUCCEEDED,
  payload: data,
});

export const updateCurrencyFailed = (data) => ({
  type: TYPES.UPDATE_CURRENCY_FAILED,
  payload: data,
});

export const addCurrencyInitiated = (data) => ({
  type: TYPES.ADD_CURRENCY_INITIATED,
  payload: data,
});

export const addCurrencySucceeded = (data) => ({
  type: TYPES.ADD_CURRENCY_SUCCEEDED,
  payload: data,
});

export const addCurrencyFailed = (data) => ({
  type: TYPES.ADD_CURRENCY_FAILED,
  payload: data,
});

export const removeCurrencyInitiated = (data) => ({
  type: TYPES.REMOVE_CURRENCY_INITIATED,
  payload: data,
});

export const removeCurrencySucceeded = (data) => ({
  type: TYPES.REMOVE_CURRENCY_SUCCEEDED,
  payload: data,
});

export const removeCurrencyFailed = (data) => ({
  type: TYPES.REMOVE_CURRENCY_FAILED,
  payload: data,
});
