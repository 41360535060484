import { call, put, takeLatest } from 'redux-saga/effects';
import API from './requests';
import { fetchRateFailed, fetchRateSucceeded } from './actions';
import { FETCH_RATE_FAILED, FETCH_RATE_INITIATED, FETCH_RATE_SUCCEEDED } from './types';

const INITIAL_STATE = {
  result: [],
  loading: false,
  Count: 0,
  ScannedCount: 0,
  initialLoading: false,
};

export function* getRateRequest() {
  try {
    const data = yield call(API.getRate);

    yield put(fetchRateSucceeded(data));
  } catch (e) {
    yield put(fetchRateFailed(e));
  }
}

export function* GetRate() {
  yield takeLatest(FETCH_RATE_INITIATED, getRateRequest);
}

export const selectRate = (state) => state?.rate;
export default function rateReducer(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_RATE_SUCCEEDED:
      return { ...payload, loading: false, initialLoading: true };
    case FETCH_RATE_FAILED:
      return { ...state, error: payload, loading: false };
    case FETCH_RATE_INITIATED:
      return {
        ...state,
        loading: !state.initialLoading,
      };
    default:
      return state;
  }
}
