import { call, put, takeLatest } from 'redux-saga/effects';
import API from './requests';
import {
  fetchBalanceFailed,
  fetchBalanceSucceeded,
  getMoreBalanceSucceeded,
  getMoreBalanceFailed,
  getPrevBalanceSucceeded,
  getPrevBalanceFailed,
} from './actions';
import {
  FETCH_BALANCE_INITIATED,
  FETCH_BALANCE_SUCCEEDED,
  FETCH_BALANCE_FAILED,
  FILTER_BALANCE_INITIATED,
  GET_MORE_BALANCE_INITIATED,
  GET_MORE_BALANCE_SUCCEEDED,
  GET_MORE_BALANCE_FAILED,
  GET_PREVIOUS_BALANCE_INITIATED,
  GET_PREVIOUS_BALANCE_SUCCEEDED,
  GET_PREVIOUS_BALANCE_FAILED,
  SET_PAGINATION_LIMIT,
} from './types';
import { paginationDefaultLimit } from '../../../utils/constants';

export const INITIAL_STATE = {
  Items: [],
  loading: false,
  emailUidMap: {},
  filters: {},
  nextCursor: '',
  cursorCache: [''],
  cursor: '',
  limit: paginationDefaultLimit,
  filterLoading: false,
  paginationLoading: false,
};

export function* getBalanceRequest(action) {
  try {
    const data = yield call(API.getBalanceByUsername, action.payload);

    yield put(fetchBalanceSucceeded(data));
  } catch (e) {
    yield put(fetchBalanceFailed(e));
  }
}

export function* getMoreBalanceRequest(action) {
  try {
    const data = yield call(API.getBalanceByUsername, action.payload);

    yield put(getMoreBalanceSucceeded(data));
  } catch (e) {
    yield put(getMoreBalanceFailed(e));
  }
}

export function* getPrevBalanceRequest(action) {
  try {
    const data = yield call(API.getBalanceByUsername, action.payload);

    yield put(getPrevBalanceSucceeded(data));
  } catch (e) {
    yield put(getPrevBalanceFailed(e));
  }
}

export function* GetBalance() {
  yield takeLatest(FETCH_BALANCE_INITIATED, getBalanceRequest);
}

export function* GetMoreBalance() {
  yield takeLatest(GET_MORE_BALANCE_INITIATED, getMoreBalanceRequest);
}

export function* GetPrevBalance() {
  yield takeLatest(GET_PREVIOUS_BALANCE_INITIATED, getPrevBalanceRequest);
}

export const selectBalance = (state) => state?.balance;
export const selectFilters = (state) => state?.balance?.filters;

export default function balanceReducer(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_BALANCE_SUCCEEDED:
      return { ...state, ...payload, loading: false };
    case FETCH_BALANCE_FAILED:
      return { ...state, error: payload, loading: false };
    case FETCH_BALANCE_INITIATED:
      return {
        ...state,
        loading: true,
      };
    case FILTER_BALANCE_INITIATED:
      return {
        ...state,
        filters: payload,
        cursorCache: [''],
        cursor: '',
      };
    case GET_MORE_BALANCE_INITIATED:
      return {
        ...state,
        cursor: state.nextCursor,
        paginationLoading: true,
      };
    case GET_MORE_BALANCE_SUCCEEDED:
      return {
        ...state,
        ...payload,
        cursorCache: [...state.cursorCache, state.cursor],
        paginationLoading: false,
      };
    case GET_MORE_BALANCE_FAILED:
      return {
        ...state,
        paginationLoading: false,
        cursor: state.cursorCache[state.cursorCache.length - 1],
        error: payload,
      };
    case GET_PREVIOUS_BALANCE_INITIATED:
      return {
        ...state,
        paginationLoading: true,
        cursor: state.cursorCache[state.cursorCache.length - 2],
      };
    case GET_PREVIOUS_BALANCE_SUCCEEDED:
      state.cursorCache.pop();
      return {
        ...state,
        ...payload,
        paginationLoading: false,
      };
    case GET_PREVIOUS_BALANCE_FAILED:
      return {
        ...state,
        paginationLoading: false,
        cursor: state.cursorCache[state.cursorCache.length - 1],
        error: payload,
      };
    case SET_PAGINATION_LIMIT:
      return {
        ...state,
        cursorCache: [''],
        cursor: '',
        limit: payload,
      };
    default:
      return state;
  }
}
