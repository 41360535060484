import * as TYPES from './types';

export const fetchSignInInitiated = (data) => ({
  type: TYPES.FETCH_SIGNIN_INITIATED,
  payload: data,
});

export const fetchSignInSucceeded = (data) => ({
  type: TYPES.FETCH_SIGNIN_SUCCEEDED,
  payload: data,
});

export const fetchSignInFailed = (data) => ({
  type: TYPES.FETCH_SIGNIN_FAILED,
  payload: data,
});

export const fetchPasswordResetInitiated = (data) => ({
  type: TYPES.FETCH_PASSWORD_RESET_INITIATED,
  payload: data,
});

export const fetchPasswordResetSucceeded = (data) => ({
  type: TYPES.FETCH_PASSWORD_RESET_SUCCEEDED,
  payload: data,
});

export const fetchPasswordResetFailed = (data) => ({
  type: TYPES.FETCH_PASSWORD_RESET_FAILED,
  payload: data,
});

export const fetchSignOutInitiated = (data) => ({
  type: TYPES.FETCH_SIGNOUT_INITIATED,
  payload: data,
});
