import React, { useState, useEffect } from 'react';
import { Switch } from 'antd';
import styles from './index.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { haltTrade, fetchhaltTrade } from '../store/modules/settings/actions';

export default function HaltTrade() {
  const [halt, setHalt] = useState(false);

  const dispatch = useDispatch();
  const { haltValue, loading } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(fetchhaltTrade());
  }, [dispatch]);

  useEffect(() => {
    setHalt(haltValue);
  }, [haltValue]);

  const toggleHaltState = () => {
    setHalt(!halt);
    dispatch(haltTrade({ key: 'halt_trade', value: !halt }));
  };

  return (
    <div className={styles.mb_10}>
      <span className={styles.label}>Halt Trade:</span>
      <Switch
        className={halt ? styles.bg_primary : styles.bg_gray}
        checkedChildren="On"
        unCheckedChildren="Off"
        defaultChecked
        checked={halt}
        onChange={toggleHaltState}
        loading={loading}
      />
    </div>
  );
}
