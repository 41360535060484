import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import './App.less';
import Layout from './layouts';
import Login from './pages/auth/login';
import ResetPassword from './pages/auth/reset-password';
import Balance from './pages/home/balance';
import RateWrapper from './pages/home/rate-wrapper';
import Transaction from './pages/home/transaction';
import AddTransaction from './pages/home/add-transaction';
import Print from './pages/home/print';
import Payee from './pages/home/payee';
import Settings from './pages/home/settings';

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/login">
            <Layout>
              <Login />
            </Layout>
          </Route>
          <Route path="/reset-password">
            <Layout>
              <ResetPassword />
            </Layout>
          </Route>
          <Route path="/home">
            <Layout>
              <Home />
            </Layout>
          </Route>
          <Route path="/">
            <Redirect to="/home" />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/print`}>
        <Print />
      </Route>
      <Route path={`${match.path}/balance`}>
        <Balance />
      </Route>
      <Route path={`${match.path}/transaction/add`}>
        <AddTransaction />
      </Route>
      <Route path={`${match.path}/transaction`}>
        <Transaction />
      </Route>
      <Route path={`${match.path}/rate`}>
        <RateWrapper />
      </Route>
      <Route path={`${match.path}/payee`}>
        <Payee />
      </Route>
      <Route path={`${match.path}/settings`}>
        <Settings />
      </Route>
      <Route path={`${match.path}/`}>
        <Redirect to={`${match.path}/transaction`} />
      </Route>
    </Switch>
  );
}
