import { combineReducers } from 'redux';
import balance from './modules/balance';
import transaction from './modules/transaction';
import rate from './modules/rate';
import auth from './modules/auth';
import currency from './modules/currency';
import payee from './modules/payee';
import settings from './modules/settings';

export default combineReducers({
  balance,
  transaction,
  rate,
  auth,
  currency,
  payee,
  settings,
});
