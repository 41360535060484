import {
  HALT_TRADE_FAILED,
  HALT_TRADE_REQUESTED,
  HALT_TRADE_SUCCESS,
  FETCH_HALT_TRADE_FAILED,
  FETCH_HALT_TRADE_REQUESTED,
  FETCH_HALT_TRADE_SUCCESS,
  NEW_TRADE_ALERT,
} from './types';

export const haltTrade = (data) => {
  return {
    type: HALT_TRADE_REQUESTED,
    payload: data,
  };
};

export const haltTradeSuccessful = (data) => ({
  type: HALT_TRADE_SUCCESS,
  payload: data,
});

export const haltTradeFailed = (data) => ({
  type: HALT_TRADE_FAILED,
  payload: data,
});

export const fetchhaltTrade = (data) => {
  return {
    type: FETCH_HALT_TRADE_REQUESTED,
    payload: data,
  };
};

export const fetchhaltTradeSuccessful = (data) => ({
  type: FETCH_HALT_TRADE_SUCCESS,
  payload: data,
});

export const fetchhaltTradeFailed = (data) => ({
  type: FETCH_HALT_TRADE_FAILED,
  payload: data,
});

export const setTradeAlert = (data) => ({
  type: NEW_TRADE_ALERT,
  payload: data,
});
