import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Form, Input, Button } from 'antd';
import { selectAuth } from '../../store/modules/auth';
import { fetchPasswordResetInitiated } from '../../store/modules/auth/actions';

import styles from './reset-password.module.css';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, loading, passwordReset } = useSelector(selectAuth);

  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, [user, history]);

  useEffect(() => {
    if (passwordReset) history.push('/home/transaction');
  }, [passwordReset, history]);

  const formSubmit = ({ password }) => {
    dispatch(fetchPasswordResetInitiated({ user, password }));
  };

  const validationRules = {
    password: [{ required: true, message: 'Please input your new password!' }],
    confirmPassword: [
      { required: true, message: 'Please confirm your password!' },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('The two passwords that you entered do not match!'));
        },
      }),
    ],
  };

  return (
    <div className={styles.content}>
      <h1>Reset your password</h1>
      <div className={styles.center}>
        <Form onFinish={formSubmit} {...layout} name="basic" initialValues={{ remember: true }}>
          <Form.Item label="New password" name="password" rules={validationRules.password}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm"
            name="confirmPassword"
            dependencies={['password']}
            rules={validationRules.confirmPassword}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button loading={loading} shape="round" type="primary" htmlType="submit">
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 },
};

export default ResetPassword;
