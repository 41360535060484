import request from '../../../utils/request';

const getBalanceByUsername = async (data) => {
  const url = `/v5/nk/query/balance?${new URLSearchParams(data)}`;
  const response = await request.get(url);
  const items = response.data.result;
  const nextCursor = response?.data?.nextCursor || '';
  // Cache the email -> uid map.
  // In v5, we use uid as the only identifier for users, but it makes more
  //  sense to search for emails (aka usernames). This a hacky workaround,
  //  and a side effect of this is that now we can only search for exact
  //  matchings.
  // TODO: Update the API endpoint to support searching on username/email
  //  with substring matching. This requires a GSI on email.
  const emailUidMap = {};
  for (let item of items) {
    if (item.email) emailUidMap[item.email] = item.uid;
  }
  return {
    ...response,
    Items: items,
    emailUidMap,
    nextCursor,
  };
};

const actions = {
  getBalanceByUsername,
};

export default actions;
