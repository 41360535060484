/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import Proptypes from 'prop-types';

import { Pagination } from 'antd';

import transactionStyles from './ant-pagination.module.css';

import { paginationDefaultLimit } from '../utils/constants';

const AntPagination = ({
  disablePrev,
  disableNext,
  onChange,
  onPrevBtnClick,
  onNextBtnClick,
  pageSize,
  isLoading,
}) => {
  const renderItem = useCallback(
    (_, type, originalElement) => {
      if (type === 'prev') {
        return (
          <button className={transactionStyles.transactionBtn}>
            <a disabled={disablePrev} onClick={!disablePrev ? onPrevBtnClick : null}>
              Previous
            </a>
          </button>
        );
      } else if (type === 'next') {
        return (
          <button className={transactionStyles.transactionBtn}>
            <a disabled={disableNext} onClick={!disableNext ? onNextBtnClick : null}>
              Next
            </a>
          </button>
        );
      }
      return originalElement;
    },
    [disablePrev, disableNext, onPrevBtnClick, onNextBtnClick],
  );

  const handleChange = useCallback((page, pageSize) => onChange(page, pageSize), [onChange]);

  return (
    <div className={isLoading ? transactionStyles.transactionDisable : ''}>
      <div className={isLoading ? transactionStyles.transactionDisableOverlay : ''} />
      <Pagination
        onChange={handleChange}
        pageSize={pageSize}
        defaultPageSize={paginationDefaultLimit}
        itemRender={renderItem}
        showSizeChanger
      />
    </div>
  );
};

AntPagination.propTypes = {
  disablePrev: Proptypes.bool,
  disableNext: Proptypes.bool,
  isLoading: Proptypes.bool,
  pageSize: Number,
  onChange: Proptypes.func,
  onNextBtnClick: Proptypes.func,
  onPrevBtnClick: Proptypes.func,
};
AntPagination.defaultProps = {
  disablePrev: true,
  disableNext: true,
  isLoading: false,
  pageSize: paginationDefaultLimit,
  onChange: () => {},
  onNextBtnClick: () => {},
  onPrevBtnClick: () => {},
};

export default AntPagination;
