export const TRANSACTION_STATE = {
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected',
  PENDING: 'pending',
};

export const TRANSACTION_TYPE = {
  remittance: {
    name: 'Remittance',
    value: 'remittance',
  },
  transfertobank: {
    name: 'Transfer to Bank',
    value: 'transfertobank',
  },
  sellfx: {
    name: 'Sell FX',
    value: 'sellfx',
  },
  buyfx: {
    name: 'Buy FX',
    value: 'buyfx',
  },
  credit: {
    name: 'HKD Credit',
    value: 'credit',
  },
  fee: {
    name: 'HKD Fee',
    value: 'fee',
  },
  adjustment: {
    name: 'Balance Adjustment',
    value: 'adjustment',
  },
};

export const LOCAL_CURRENCY = ['HKD'];

export const ADD_TRANSACTION_BUTTON_TEXT_TYPE = {
  addTransaction: 'Add transaction',
  offlineTransaction: 'Request offline transaction',
};

export const ALLOWED_OFFLINE_TRANSACTION_TYPE = [
  TRANSACTION_TYPE.sellfx.value,
  TRANSACTION_TYPE.buyfx.value,
  TRANSACTION_TYPE.transfertobank.value,
  TRANSACTION_TYPE.remittance,
];

export const EXPIRATION_SECONDS = 3600;

export const CURRENCY = [
  'AED',
  'AUD',
  'BHD',
  'CAD',
  'CHF',
  'CNY',
  'DKK',
  'EUR',
  'GBP',
  'HKD',
  'IDR',
  'INR',
  'JPY',
  'KRW',
  'KWD',
  'MOP',
  'MYR',
  'NOK',
  'NZD',
  'OMR',
  'PHP',
  'RUB',
  'SAR',
  'SEK',
  'SGD',
  'THB',
  'TWD',
  'USD',
  'VND',
  'ZAR',
];

export const BUY_SELL_ORDER_OBJECT = {
  type: null,
  email: null,
  status: null,
  created: null,
  updated: null,
  otp: null,
  foreign_currency: null,
  foreign_amount: null,
  local_currency: null,
  local_amount: null,
  admin_id: null,
  balance_updated_1: null,
  balance_updated_2: null,
  transaction_id: null,
  fee: null,
};

export const REMITTANCE_ORDER_OBJECT = {
  type: null,
  email: null,
  status: null,
  created: null,
  updated: null,
  otp: null,
  message: null,
  purpose: null,
  remarks: null,
  recipient: null,
  foreign_currency: null,
  foreign_amount: null,
  admin_id: null,
  balance_updated_1: null,
  balance_updated_2: null,
  transaction_id: null,
  fee: null,
};
export const paginationDefaultLimit = 50;

export const NKDB_KEY = '___USE_LEDGER';

export const NonStandardCurrencyLookup = {
  CNH: 'RMQ',
  IDR: 'IDQ',
  MYR: 'MYQ',
  SGD: 'SGQ',
  THB: 'THQ',
  TWD: 'TWQ',
  AUD: 'AUQ',
  CAD: 'CAQ',
  CHF: 'CHQ',
  EUR: 'EUQ',
  GBP: 'GBQ',
  JPY: 'JPQ',
  NZD: 'NZQ',
  USD: 'USQ',
};
