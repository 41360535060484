import request from '../../../utils/request';

const getRate = async () => {
  const response = await request.get('/v5/user/query/rate');
  return response;
};

const actions = {
  getRate,
};

export default actions;
