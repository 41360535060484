import { Descriptions, Table } from 'antd';
import { snakeCaseToTitle, userBalanceToArray } from '../utils/libs';

import styles from './index.module.css';

const getDescriptionItems = (dictionary) => {
  const data = [];
  for (const [key, value] of Object.entries(dictionary)) {
    let label;
    switch (key) {
      case 'uid':
        label = 'UID';
        break;
      case 'nkcid':
        label = 'NK Customer ID';
        break;
      case 'nkgl':
        label = 'NK General Ledger ID';
        break;
      case 'nium':
        label = 'Is Theia FX User';
        break;
      default:
        label = snakeCaseToTitle(key);
    }
    data.push(
      key !== 'nium' ? (
        <Descriptions.Item label={label} key={label}>
          {[null, undefined].includes(value) ? 'Not Set' : value}
        </Descriptions.Item>
      ) : (
        <Descriptions.Item label={label} key={label}>
          {value > 0 ? 'Yes' : 'No'}
        </Descriptions.Item>
      ),
    );
  }
  return data;
};

export const UserInfo = ({ visible, user, balance }) => (
  <>
    {visible && user && balance && (
      <div className={styles.user_info}>
        <Descriptions className="user-desc-tile user_info_header" title="User Details" column={1}>
          {getDescriptionItems(user)}
        </Descriptions>
        <Table
          columns={[
            {
              title: 'Currency',
              dataIndex: 'currency',
              key: 'currency',
            },
            {
              title: 'Available balance',
              dataIndex: 'availableBalance',
              key: 'availableBalance',
            },
            {
              title: 'Ledger balance',
              dataIndex: 'ledgerBalance',
              key: 'ledgerBalance',
            },
          ]}
          dataSource={userBalanceToArray(balance)}
          size="small"
          pagination={false}
          title={() => <div className="ant-descriptions-title">User Balance</div>}
        />
      </div>
    )}
  </>
);
