import { call, put, takeLatest } from 'redux-saga/effects';
import API from './request';
import tranxAPI from '../transaction/requests';
import {
  FETCH_PAYEES_REQUESTED,
  FETCH_PAYEE_SUCCESS,
  FETCH_PAYEE_FAILED,
  DELETE_PAYEE_FAILED,
  DELETE_PAYEE_REQUESTED,
  DELETE_PAYEE_SUCCESS,
  ADD_PAYEE_SUCCEEDED,
  ADD_PAYEE_FAILED,
  ADD_PAYEE_INITIATED,
  FETCH_PAYEE_USER_INFO_INITIATED,
  FETCH_PAYEE_USER_INFO_SUCCEEDED,
  FETCH_PAYEE_USER_INFO_FAILED,
  CLEAR_PAYEE_USER_INFO_ERROR,
  CLEAR_ADD_PAYEE_ERROR,
  RESET_ADD_PAYEE,
  RESET_PAYEE,
  GET_MORE_PAYEEDATA_INITIATED,
  GET_MORE_PAYEEDATA_FAILED,
  GET_MORE_PAYEEDATA_SUCCESSFUL,
  GET_PREV_PAYEEDATA_INITIATED,
  GET_PREV_PAYEEDATA_SUCCESSFUL,
  GET_PREV_PAYEEDATA_FAILED,
  SET_PAYEE_PAGINATION_LIMIT,
  GET_USER_EMAIL_PAYEE_INITIATED,
  GET_USER_EMAIL_PAYEE_SUCCESFUL,
  GET_USER_EMAIL_PAYEE_FAILED,
} from './types';

import {
  fetchPayeeSuccessful,
  addPayeeFailed,
  addPayeeSucceeded,
  deletePayeeFailed,
  deletePayeeSuccess,
  fetchPayeeFailed,
  fetchUserInfoSucceeded,
  fetchUserInfoFailed,
  getMorePayeeDataSuccesful,
  getMorePayeeDataFailed,
  getPrevPayeeDataFailed,
  getPrevPayeeDataSuccessful,
  getUserPayeeSuccessful,
  getUserPayeeFailed,
} from './actions';
import { paginationDefaultLimit } from '../../../utils/constants';
import { showTimeInHKT } from '../../../utils/libs';

export const INITIAL_STATE = {
  users: [],
  loading: false,
  payeeDeleted: null,
  filters: {},
  error: null,
  userInfo: {},
  userInfoError: '',
  addPayeeLoading: false,
  addPayeeError: '',
  newPayeeData: null,
  limit: paginationDefaultLimit,
  nextCursor: '',
  cursorCache: [''],
  cursor: '',
};

export function* handleGetPayee(action) {
  try {
    const users = yield call(API.getPayee, action.payload);
    yield put(fetchPayeeSuccessful(users));
  } catch (err) {
    yield put(fetchPayeeFailed(err));
  }
}

export function* GetPayee() {
  yield takeLatest(FETCH_PAYEES_REQUESTED, handleGetPayee);
}

export function* handleDeletePayee(action) {
  try {
    yield call(API.deletePayee, action.payload);
    yield put(deletePayeeSuccess(action.payload));
  } catch (error) {
    yield put(deletePayeeFailed(error.message));
  }
}

export function* DeletePayee() {
  yield takeLatest(DELETE_PAYEE_REQUESTED, handleDeletePayee);
}

function* handleAddPayee(action) {
  try {
    const data = yield call(API.addPayee, action.payload);
    if (data.success) {
      yield call(tranxAPI.addTransactionOffline, {
        uid: action.payload.uid,
        type: 'custom',
        title: 'A new payee has been added to your account',
        priority: 1,
        should_push: true,
        message: `A new payee ${
          action.payload.nickname || action.payload.name
        } has been added to your account by an RFX admin at ${showTimeInHKT()}`,
      });
    }

    yield put(addPayeeSucceeded(data.data));
  } catch (err) {
    yield put(addPayeeFailed(err.data.messages || err.message));
  }
}

export function* AddPayeeSaga() {
  yield takeLatest(ADD_PAYEE_INITIATED, handleAddPayee);
}

function* handleGetUserEmailInfo(action) {
  try {
    const data = yield call(API.getUserInfo, action.payload);
    yield put(fetchUserInfoSucceeded(data));
  } catch (e) {
    yield put(fetchUserInfoFailed(e.data.messages || e.message));
  }
}

export function* GetUserEmailInfo() {
  yield takeLatest(FETCH_PAYEE_USER_INFO_INITIATED, handleGetUserEmailInfo);
}

export function* getMorePayeeData(action) {
  try {
    const data = yield call(API.getPayee, action.payload);
    yield put(getMorePayeeDataSuccesful(data));
  } catch (error) {
    yield put(getMorePayeeDataFailed(error));
  }
}

export function* GetMorePayeeData() {
  yield takeLatest(GET_MORE_PAYEEDATA_INITIATED, getMorePayeeData);
}

export function* getPrevPayeeData(action) {
  try {
    const data = yield call(API.getPayee, action.payload);
    yield put(getPrevPayeeDataSuccessful(data));
  } catch (error) {
    yield put(getPrevPayeeDataFailed(error));
  }
}

export function* GetPrevPayeeTransaction() {
  yield takeLatest(GET_PREV_PAYEEDATA_INITIATED, getPrevPayeeData);
}

export function* getUserPayeeData(action) {
  try {
    const data = yield call(API.getPayee, action.payload);
    yield put(getUserPayeeSuccessful(data));
  } catch (error) {
    yield put(getUserPayeeFailed(error));
  }
}

export function* GetUserPayee() {
  yield takeLatest(GET_USER_EMAIL_PAYEE_INITIATED, getUserPayeeData);
}

export const selectPayee = (state) => state.payee;

const payee = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_EMAIL_PAYEE_INITIATED:
      return {
        ...state,
        loading: true,
        filters: action.payload,
      };
    case ADD_PAYEE_INITIATED:
      return { ...state, addPayeeLoading: true };
    case DELETE_PAYEE_REQUESTED:
    case FETCH_PAYEES_REQUESTED:
      return { ...state, loading: true };
    case GET_PREV_PAYEEDATA_INITIATED:
      return { ...state, cursor: state.cursorCache[state.cursorCache.length - 2], loading: true };
    case GET_PREV_PAYEEDATA_SUCCESSFUL:
      state.cursorCache.pop();
      return {
        ...state,
        loading: false,
        users: action.payload?.data.result,
        nextCursor: action.payload?.data.nextCursor,
      };
    case GET_PREV_PAYEEDATA_FAILED:
      return {
        ...state,
        cursor: state.cursorCache[state.cursorCache.length - 1],
        error: action.payload,
        loading: false,
      };
    case ADD_PAYEE_SUCCEEDED:
      return {
        ...state,
        users: [action.payload, ...state.users],
        addPayeeLoading: false,
        addPayeeError: '',
        newPayeeData: action.payload,
      };
    case GET_MORE_PAYEEDATA_INITIATED:
      return { ...state, cursor: state.nextCursor, loading: true };
    case GET_MORE_PAYEEDATA_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        users: action.payload?.data.result,
        nextCursor: action.payload?.data.nextCursor,
        cursorCache: [...state.cursorCache, state.cursor],
      };
    case GET_MORE_PAYEEDATA_FAILED:
      return {
        ...state,
        error: action.payload,
        cursor: state.cursorCache[state.cursorCache.length - 1],
      };
    case GET_USER_EMAIL_PAYEE_SUCCESFUL:
    case FETCH_PAYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload?.data.result,
        nextCursor: action.payload.data?.nextCursor,
      };
    case FETCH_PAYEE_USER_INFO_SUCCEEDED:
      return { ...state, loading: false, userInfo: action.payload.data, userInfoError: '' };
    case DELETE_PAYEE_SUCCESS:
      return {
        ...state,
        payeeDeleted: Symbol(),
        users: state.users.filter(({ id }) => id !== action.payload),
        loading: false,
      };
    case ADD_PAYEE_FAILED:
      return { ...state, addPayeeError: action.payload, addPayeeLoading: false };
    case DELETE_PAYEE_FAILED:
    case GET_USER_EMAIL_PAYEE_FAILED:
    case FETCH_PAYEE_FAILED:
      return { ...state, loading: false, error: action.payload };
    case FETCH_PAYEE_USER_INFO_FAILED:
      return { ...state, userInfo: {}, userInfoError: action.payload };
    case CLEAR_PAYEE_USER_INFO_ERROR:
      return { ...state, userInfoError: '', loading: false };
    case RESET_ADD_PAYEE:
      return { ...state, addPayeeError: '', addPayeeLoading: false, newPayeeData: null };
    case RESET_PAYEE:
      return { ...INITIAL_STATE, filters: state.filters };
    case CLEAR_ADD_PAYEE_ERROR:
      return { ...state, addPayeeError: '' };
    case SET_PAYEE_PAGINATION_LIMIT:
      return { ...state, cursorCache: [''], cursor: '', limit: action.payload };
    default:
      return state;
  }
};

export default payee;
