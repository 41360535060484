import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Select, Checkbox, Descriptions } from 'antd';
import DataObject from 'dataobject-parser';

import styles from './add-transaction.module.css';
import {
  TRANSACTION_TYPE,
  ADD_TRANSACTION_BUTTON_TEXT_TYPE,
  ALLOWED_OFFLINE_TRANSACTION_TYPE,
  EXPIRATION_SECONDS,
} from '../../utils/constants';
import {
  addTransactionInitiated,
  fetchUserInfoInitiated,
  clearUserInfoErrorSuccess,
  fetchPayeeInitiated,
} from '../../store/modules/transaction/actions';
import { selectTransaction, selectPayees } from '../../store/modules/transaction';
import { formatAmount, openError, openNotification } from '../../utils/libs';
import NumberFormat from 'react-number-format';
import { UserInfo } from '../../components/UserInfo';
import { selectRate } from '../../store/modules/rate';
import { fetchRateInitiated } from '../../store/modules/rate/actions';

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const AddTransaction = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { loading, userInfoCheck } = useSelector(selectTransaction);
  const { data: payeesList } = useSelector(selectPayees);
  const { result: rateList } = useSelector(selectRate);

  const [currentUserInfo, setCurrentUserInfo] = useState({});
  const [isMultipleUser, setIsMultipleUser] = useState(false);
  const userInfoSuccess = userInfoCheck?.response?.success;
  const userInfoError = userInfoCheck?.response?.error;

  const [transactionType, setTransactionType] = useState();
  const [buttonText, setButtonText] = useState(ADD_TRANSACTION_BUTTON_TEXT_TYPE.addTransaction);
  const [offlineAddTransactionCheckbox, setOfflineAddTransactionCheckbox] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const usernameDefaultValue = useRef('');
  const validateStatus = useRef('');
  const help = useRef('');

  const clearState = useCallback(() => {
    validateStatus.current = '';
    setTransactionType();
    setButtonText(ADD_TRANSACTION_BUTTON_TEXT_TYPE.addTransaction);
    setOfflineAddTransactionCheckbox(false);
    setShowDetails(false);
    setIsMultipleUser(false);
    usernameDefaultValue.current = '';
    form.resetFields();
  }, [form]);

  const getRate = useCallback(() => {
    dispatch(fetchRateInitiated());
  }, [dispatch]);

  useEffect(() => {
    if (offlineAddTransactionCheckbox) {
      setButtonText(ADD_TRANSACTION_BUTTON_TEXT_TYPE.offlineTransaction);
    }
  }, [offlineAddTransactionCheckbox]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      getRate();
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [rateList, getRate]);

  const successCallback = useCallback(
    (placement, requestionInfo) => {
      openNotification(placement, requestionInfo);
      setTimeout(() => {
        clearState();
      }, 1000);
    },
    [clearState],
  );

  const failureCallback = useCallback(
    (errorMessage) => {
      openError(
        '',
        `The ${form.getFieldValue('type')} transaction was unsuccessful because: ${
          errorMessage || 'Unhandled error'
        }`,
      );
    },
    [form],
  );

  const createTransaction = useCallback(
    (values) => {
      let sub = {};

      switch (values.type) {
        case TRANSACTION_TYPE.sellfx.value:
        case TRANSACTION_TYPE.buyfx.value:
          sub = {
            rate:
              parseFloat(formatAmount(values.local_amount)) /
              parseFloat(formatAmount(values.foreign_amount)),
          };
          break;
        case TRANSACTION_TYPE.credit.value:
        case TRANSACTION_TYPE.fee.value:
        case TRANSACTION_TYPE.transfertobank.value:
          sub = {
            local_currency: 'HKD',
          };
          break;
        case TRANSACTION_TYPE.remittance.value:
          values = DataObject.transpose(values).data();
          break;
        case TRANSACTION_TYPE.adjustment.value:
          sub =
            values.currency === 'HKD'
              ? {
                  local_amount: parseFloat(formatAmount(values.amount)),
                  local_currency: 'HKD',
                }
              : {
                  foreign_amount: parseFloat(formatAmount(values.amount)),
                  foreign_currency: values.currency,
                };
          break;
        default:
          break;
      }

      if (values.local_amount) sub.local_amount = parseFloat(formatAmount(values.local_amount));
      if (values.foreign_amount)
        sub.foreign_amount = parseFloat(formatAmount(values.foreign_amount));

      if (values.expiration_time) sub.expiration_time = parseFloat(values.expiration_time);

      const { uid } = currentUserInfo?.user;
      values.uid = uid;
      Object.assign(values, sub);

      if (values.offline_transaction_checkbox) {
        delete values.offline_transaction_checkbox;
        const expiration_seconds = !values.expiration_time
          ? EXPIRATION_SECONDS
          : values.expiration_time;
        delete values.expiration_time;
        const payload = {
          expiration_seconds,
          transaction: { uid: uid, ...values },
        };
        values = {};
        // This is necessary. Used in src/store/modules/transaction/index.js to determine which endpoint to use
        values.offline_transaction_checkbox = true;
        values.uid = uid;
        values.payload = payload;
        values.type = 'verification';
        values.status = 'pending';
        values.title = 'Offline Transaction Verification';
        values.message = 'Please verify your identity to confirm the offline transaction.';
        values.priority = 0;
        values.should_push = true;
      } else {
        delete values.email;
      }
      values.failureCallback = failureCallback;
      values.successCallback = successCallback;
      dispatch(addTransactionInitiated(values));
    },
    [dispatch, failureCallback, successCallback, currentUserInfo],
  );

  const checkUserValidAndGetInfo = useCallback(
    (data) => {
      dispatch(fetchUserInfoInitiated(data));
    },
    [dispatch],
  );

  const resetUserInfoSuccessErrorBooleans = useCallback(() => {
    dispatch(clearUserInfoErrorSuccess());
  }, [dispatch]);

  useEffect(() => {
    if (userInfoSuccess && Object.keys(userInfoCheck?.response?.data).length) {
      validateStatus.current = 'success';
      resetUserInfoSuccessErrorBooleans();
      if (userInfoCheck?.response?.data instanceof Array) {
        setShowDetails(false);
        setIsMultipleUser(true);
      } else {
        setCurrentUserInfo(userInfoCheck?.response?.data);
        setIsMultipleUser(false);
        setShowDetails(true);
      }
    } else if (userInfoSuccess) {
      validateStatus.current = 'error';
    }
  }, [userInfoSuccess, resetUserInfoSuccessErrorBooleans, userInfoCheck]);

  useEffect(() => {
    if (userInfoError) {
      validateStatus.current = 'error';
      resetUserInfoSuccessErrorBooleans();
      help.current = 'Username not found, please double check and try again.';
    }
  }, [userInfoError, resetUserInfoSuccessErrorBooleans]);

  const checkUsernameValidity = (e) => {
    if (usernameDefaultValue.current === e.target.value) return;
    usernameDefaultValue.current = e.target.value;
    validateStatus.current = 'validating';
    help.current = '';
    setShowDetails(false);
    setIsMultipleUser(false);
    checkUserValidAndGetInfo({ username: e.target.value });
  };

  const getPayee = useCallback(
    (params) => {
      dispatch(fetchPayeeInitiated(params));
    },
    [dispatch],
  );

  useEffect(() => {
    if (currentUserInfo && transactionType === TRANSACTION_TYPE.remittance.value) {
      const timer = setTimeout(() => {
        getPayee({ email: currentUserInfo.user.email });
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [getPayee, transactionType, currentUserInfo]);

  const onHandleUserChange = (value) => {
    if (value === '-1') {
      setCurrentUserInfo({});
      setShowDetails(false);
    } else {
      const selectedUser = userInfoCheck?.response?.data.filter((data) => {
        return data.user.uid === value;
      });
      setCurrentUserInfo(...selectedUser);
      setShowDetails(true);
    }
  };

  return (
    <div className={styles.content}>
      <h1>Add Transaction Manually</h1>
      <div className={styles.center}>
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{
            remember: true,
            offline_transaction_checkbox:
              ALLOWED_OFFLINE_TRANSACTION_TYPE.includes(transactionType),
            expiration_time: EXPIRATION_SECONDS,
          }}
          onFinish={createTransaction}
        >
          <Form.Item
            label="Username"
            name="email"
            validateTrigger={'onBlur'}
            rules={[
              {
                required: true,
                message: 'Please enter a username!',
              },
            ]}
            hasFeedback
            validateStatus={validateStatus.current}
            help={help.current}
            style={{ marginBottom: '0' }}
            className="ant-form-item-with-help"
          >
            <Input type={'email'} onBlur={checkUsernameValidity} />
          </Form.Item>
          {isMultipleUser ? (
            <>
              <Descriptions
                column={1}
                contentStyle={{ maxWidth: '91.666667%' }}
                className="multiple-users"
              >
                <Descriptions.Item>
                  Multiple users found with username {usernameDefaultValue.current}! Please choose
                  one to proceed.
                </Descriptions.Item>
                <Descriptions.Item
                  style={{ paddingLeft: '25%' }}
                  contentStyle={{ maxWidth: '89.6667%' }}
                >
                  <Select
                    defaultValue="Choose a user"
                    onChange={onHandleUserChange}
                    style={{ width: '100%' }}
                  >
                    <Option value="-1">Choose a user</Option>
                    {userInfoCheck?.response?.data.map((data, index) => (
                      <Option key={index} value={data.user.uid}>
                        UID: {data.user.uid}
                      </Option>
                    ))}
                  </Select>
                </Descriptions.Item>
              </Descriptions>
            </>
          ) : (
            ''
          )}
          <div className={styles.user_info_container}>
            <UserInfo {...currentUserInfo} visible={showDetails} />
          </div>
          <Form.Item
            name="type"
            label="Transaction type"
            rules={[
              {
                required: true,
                message: 'Please enter a type!',
              },
            ]}
          >
            <Select
              placeholder="Select a transaction type"
              allowClear
              onChange={(value) => {
                setTransactionType(value);

                if (ALLOWED_OFFLINE_TRANSACTION_TYPE.includes(value)) {
                  setOfflineAddTransactionCheckbox(true);
                  form.setFieldsValue({
                    offline_transaction_checkbox: true,
                  });
                } else {
                  setButtonText(ADD_TRANSACTION_BUTTON_TEXT_TYPE.addTransaction);
                  setOfflineAddTransactionCheckbox(false);
                }
              }}
            >
              {Object.keys(TRANSACTION_TYPE).map((key, i) => (
                <Option key={i} value={TRANSACTION_TYPE[key].value}>
                  {TRANSACTION_TYPE[key].name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {offlineAddTransactionCheckbox && (
            <OfflineTransactionCheckboxAndInput setButtonText={setButtonText} />
          )}
          {transactionType === TRANSACTION_TYPE.transfertobank.value && (
            <AmountInput label="Amount" name="local_amount" form={form} />
          )}

          {transactionType === TRANSACTION_TYPE.remittance.value && (
            <Remittance payees={payeesList?.result} currencies={rateList || []} />
          )}

          {(transactionType === TRANSACTION_TYPE.sellfx.value ||
            transactionType === TRANSACTION_TYPE.buyfx.value) && (
            <SellfxOrBuyfx currencies={rateList || []} />
          )}

          {transactionType === TRANSACTION_TYPE.credit.value && (
            <AmountInput label="HKD Amount" name="local_amount" />
          )}

          {transactionType === TRANSACTION_TYPE.fee.value && (
            <AmountInput label="HKD Amount" name="local_amount" />
          )}

          {transactionType === TRANSACTION_TYPE.adjustment.value && (
            <>
              <Form.Item label="Currency" name="currency">
                <Select placeholder="Select a currency" allowClear>
                  {[{ currency: 'HKD' }, ...rateList].map(({ currency }, i) => (
                    <Option key={i} value={currency}>
                      {currency}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Balance to adjust" name="adjustment_target">
                <Select placeholder="Select a balance" allowClear>
                  {['available', 'ledger', 'both'].map((target, i) => (
                    <Option key={i} value={target}>
                      {target}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <AmountInput label="Amount" name="amount" allowNegative={true} />
            </>
          )}

          <Form.Item {...tailLayout}>
            <Button
              style={{ width: '100%' }}
              loading={loading}
              shape="round"
              type="primary"
              htmlType="submit"
              onSubmit={form.submit}
              disabled={!showDetails}
            >
              {buttonText}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const AmountInput = ({ name, label, initialValue, allowNegative = false }) => (
  <Form.Item {...{ label, name, initialValue }}>
    <NumberFormat
      customInput={Input}
      thousandSeparator=","
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale
      {...{ allowNegative }}
    />
  </Form.Item>
);

const Remittance = ({ payees, currencies }) => (
  <>
    <Form.Item label="Currency" name="foreign_currency">
      <Select placeholder="Select a currency" allowClear>
        {currencies.map(({ currency }, i) => (
          <Option key={i} value={currency}>
            {currency}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Payee" name="recipient">
      <Select placeholder="Select a Payee" allowClear>
        {payees
          ? payees.map((payee, i) => (
              <Option key={i} value={payee.id}>
                {payee.name}
              </Option>
            ))
          : ''}
      </Select>
    </Form.Item>
    <AmountInput label="Amount" name="foreign_amount" />
    <Form.Item label="Remarks" name="remarks">
      <Input />
    </Form.Item>
    <Form.Item label="Purpose" name="purpose">
      <Input />
    </Form.Item>
    <Form.Item label="Message" name="message">
      <Input />
    </Form.Item>
  </>
);

const SellfxOrBuyfx = ({ currencies }) => (
  <>
    <Form.Item label="Foreign Currency" name="foreign_currency">
      <Select placeholder="Select a currency" allowClear>
        {currencies.map(({ currency }, i) => (
          <Option key={i} value={currency}>
            {currency}
          </Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item label="Local Currency" name="local_currency">
      <Select placeholder="Select a currency" allowClear>
        <Option value="HKD">HKD</Option>
      </Select>
    </Form.Item>
    <AmountInput label="Foreign Amount" name="foreign_amount" />
    <AmountInput label="Local Amount" name="local_amount" />
  </>
);

const OfflineTransactionCheckboxAndInput = ({ setButtonText }) => {
  const [offlineCheck, setOfflineCheck] = useState(true);
  return (
    <>
      <Form.Item
        label="Offline transaction"
        valuePropName="checked"
        name="offline_transaction_checkbox"
      >
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              setOfflineCheck(true);
              setButtonText(ADD_TRANSACTION_BUTTON_TEXT_TYPE.offlineTransaction);
            } else {
              setOfflineCheck(false);
              setButtonText(ADD_TRANSACTION_BUTTON_TEXT_TYPE.addTransaction);
            }
          }}
        ></Checkbox>
      </Form.Item>
      {offlineCheck && (
        <Form.Item label="Verification expiration time" name="expiration_time">
          <NumberFormat
            customInput={Input}
            defaultValue={EXPIRATION_SECONDS}
            allowNegative={false}
            suffix=" seconds"
          />
        </Form.Item>
      )}
    </>
  );
};

export default AddTransaction;
