import request from '../../../utils/request';

const deletePayee = async (dataId) => {
  return await request.delete(`/v6/nk/payee?id=${dataId}`);
};

const addPayee = async (data) => await request.put('/v6/nk/payee', { data });

const getUserInfo = async (params) => await request.get('/v5/nk/query/user', { params });

const getPayee = async (data) => {
  const response = await request.get(`/v6/nk/query/payee?${new URLSearchParams(data)}`);
  return {
    ...response,
  };
};
const actions = {
  deletePayee,
  addPayee,
  getUserInfo,
  getPayee,
};

export default actions;
