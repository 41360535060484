import { Button, Space, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

/**
 * Function to add column search/filter to antd table element
 * This function can be updated to add search capabilty or even toggele between both
 * @param {string} dataIndex
 * @param {Node} inputRef
 * @returns {Node}
 */
export const getColumnSearchFilterProps = (dataIndex, inputRef, actionName = 'filter') => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          inputRef.current = node;
        }}
        placeholder={`${actionName} ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          disabled={!inputRef.current?.state?.value}
          onClick={clearFilters}
          size="small"
          type="link"
          style={{ width: 50 }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            confirm();
          }}
        >
          {actionName.charAt(0).toUpperCase() + actionName.slice(1)}
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => inputRef.current.select(), 100);
    }
  },
});
