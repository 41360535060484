import { Auth } from '@aws-amplify/auth';
import { extend } from 'umi-request';
import config from '../aws-exports';

const request = extend({
  prefix: config.apiURL,
});

request.interceptors.request.use(
  async (url, options) => {
    const res = await Auth.currentSession();
    const jwt = res.getIdToken().getJwtToken();

    return {
      url,
      options: { ...options, headers: { Authorization: jwt, 'Content-Type': 'application/json' } },
    };
  },
  { global: true },
);

export default request;
