import { call, put, takeLatest } from 'redux-saga/effects';
import API from './requests';
import {
  fetchCurrencyFailed,
  fetchCurrencySucceeded,
  updateCurrencyFailed,
  updateCurrencySucceeded,
  addCurrencyFailed,
  addCurrencySucceeded,
  removeCurrencyFailed,
  removeCurrencySucceeded,
  fetchCurrencyInitiated,
} from './actions';
import {
  CLEAR_ERR_SUCCESS_MSGS,
  FETCH_CURRENCY_INITIATED,
  FETCH_CURRENCY_SUCCEEDED,
  FETCH_CURRENCY_FAILED,
  UPDATE_CURRENCY_INITIATED,
  UPDATE_CURRENCY_SUCCEEDED,
  UPDATE_CURRENCY_FAILED,
  ADD_CURRENCY_INITIATED,
  ADD_CURRENCY_SUCCEEDED,
  ADD_CURRENCY_FAILED,
  REMOVE_CURRENCY_INITIATED,
  REMOVE_CURRENCY_SUCCEEDED,
  REMOVE_CURRENCY_FAILED,
} from './types';

const INITIAL_STATE = {
  schema: [],
  loading: false,
};

export function* ClearMsgs() {
  yield takeLatest(CLEAR_ERR_SUCCESS_MSGS, () => {});
}

export function* getCurrencyRequest(action) {
  try {
    const data = yield call(API.getCurrency, action.payload);

    yield put(fetchCurrencySucceeded(data));
  } catch (e) {
    yield put(fetchCurrencyFailed(e));
  }
}

export function* GetCurrency() {
  yield takeLatest(FETCH_CURRENCY_INITIATED, getCurrencyRequest);
}

export function* updateCurrencyRequest(action) {
  try {
    const data = yield call(API.putCurrency, action.payload);

    yield put(
      updateCurrencySucceeded({
        ...data,
        successMsg: `Your changes have been received for ${action.payload.currency}, and will be applied within two minutes.`,
      }),
    );
    yield put(
      fetchCurrencyInitiated({ type: action.payload.type, currency: action.payload.currency }),
    );
  } catch (e) {
    yield put(updateCurrencyFailed(e));
  }
}

export function* UpdateCurrency() {
  yield takeLatest(UPDATE_CURRENCY_INITIATED, updateCurrencyRequest);
}

export function* addCurrencyRequest(action) {
  try {
    const data = yield call(API.addCurrency, action.payload);

    yield put(
      addCurrencySucceeded({
        ...data,
        successMsg: `successfully created the currency ${action.payload.currency}. It will show up in the table and take effect in a few minutes`,
      }),
    );
  } catch (e) {
    yield put(addCurrencyFailed(e));
  }
}

export function* AddCurrency() {
  yield takeLatest(ADD_CURRENCY_INITIATED, addCurrencyRequest);
}

export function* removeCurrencyRequest(action) {
  try {
    const data = yield call(API.removeCurrency, action.payload);

    yield put(
      removeCurrencySucceeded({
        ...data,
        successMsg: `successfully deleted the currency ${action.payload.currency}. The changes will take effect in a few minutes`,
      }),
    );
  } catch (e) {
    yield put(removeCurrencyFailed(e));
  }
}

export function* RemoveCurrency() {
  yield takeLatest(REMOVE_CURRENCY_INITIATED, removeCurrencyRequest);
}

export const selectCurrency = (state) => state?.currency;
export default function rateReducer(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case CLEAR_ERR_SUCCESS_MSGS:
      return { ...state, error: null, successMsg: null };
    case FETCH_CURRENCY_SUCCEEDED:
    case UPDATE_CURRENCY_SUCCEEDED:
    case ADD_CURRENCY_SUCCEEDED:
    case REMOVE_CURRENCY_SUCCEEDED:
      return { ...payload, loading: false };
    case FETCH_CURRENCY_FAILED:
    case UPDATE_CURRENCY_FAILED:
    case REMOVE_CURRENCY_FAILED:
    case ADD_CURRENCY_FAILED:
      return { ...state, error: payload, loading: false };
    case FETCH_CURRENCY_INITIATED:
    case ADD_CURRENCY_INITIATED:
    case REMOVE_CURRENCY_INITIATED:
      return {
        ...state,
        loading: !state.loading,
        error: null,
      };
    case UPDATE_CURRENCY_INITIATED:
      return {
        ...state,
        schema: null,
        loading: !state.loading,
        error: null,
      };
    default:
      return state;
  }
}
