import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
import { INITIAL_STATE as transaction } from './modules/transaction';
import { INITIAL_STATE as balance } from './modules/balance';
import { INITIAL_STATE as payee } from './modules/payee';
import { INITIAL_STATE as settings } from './modules/settings';

const sagaMiddleware = createSagaMiddleware();

// redux dev tool
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

const initialState = {
  balance,
  transaction,
  payee,
  settings,
  rate: {},
  auth: {},
  currency: {},
};

export const store = createStore(rootReducer, initialState, enhancer);

export const withProvider = (Component) => (props) =>
  (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );

sagaMiddleware.run(rootSaga);
