import request from '../../../utils/request';

const haltTrade = async (data) => {
  try {
    const res = await request
      .post(`/v7/user/kvs`, { data, getResponse: true })
      .then(({ data, response: { status } }) => ({ ...data, status }));
    return res;
  } catch (error) {
    console.log(error);
  }
};

const fetchhaltTrade = async () => {
  try {
    const res = await request.get(`/v7/user/kvs?key=halt_trade`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

const actions = {
  haltTrade,
  fetchhaltTrade,
};

export default actions;
