import { notification } from 'antd';
import { call, put, takeLatest } from 'redux-saga/effects';
import API from './request';
import {
  HALT_TRADE_REQUESTED,
  HALT_TRADE_FAILED,
  HALT_TRADE_SUCCESS,
  FETCH_HALT_TRADE_REQUESTED,
  FETCH_HALT_TRADE_FAILED,
  FETCH_HALT_TRADE_SUCCESS,
  NEW_TRADE_ALERT,
} from './types';
import {
  haltTradeFailed,
  haltTradeSuccessful,
  fetchhaltTradeFailed,
  fetchhaltTradeSuccessful,
} from './actions';

export const INITIAL_STATE = {
  loading: false,
  haltValue: false,
  muted: localStorage.getItem('trade_alert_muted') === 'true',
};

export function* handleHaltTrade(action) {
  try {
    const response = yield call(API.haltTrade, action.payload);
    if (response.success) {
      notification.success({ placement: 'topRight', message: response.message });
      yield put(haltTradeSuccessful(response.data.value));
    } else {
      notification.error({ placement: 'topRight', message: response.message });
      yield put(haltTradeFailed(response.data));
    }
  } catch (err) {
    yield put(haltTradeFailed(err));
  }
}

export function* handleFetchHaltTrade(action) {
  try {
    const response = yield call(API.fetchhaltTrade, action.payload);
    yield put(fetchhaltTradeSuccessful(response.data.value));
  } catch (err) {
    yield put(fetchhaltTradeFailed(err));
  }
}

export function* HaltTrade() {
  yield takeLatest(HALT_TRADE_REQUESTED, handleHaltTrade);
}

export function* FetchHaltTrade() {
  yield takeLatest(FETCH_HALT_TRADE_REQUESTED, handleFetchHaltTrade);
}

export const selectMuted = (state) => state?.settings?.muted;

const Settings = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HALT_TRADE_REQUESTED:
    case FETCH_HALT_TRADE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case FETCH_HALT_TRADE_SUCCESS:
    case HALT_TRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        haltValue: JSON.parse(action.payload),
      };
    case HALT_TRADE_FAILED:
    case FETCH_HALT_TRADE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case NEW_TRADE_ALERT:
      return {
        ...state,
        muted: action.payload,
      };
    default:
      return state;
  }
};

export default Settings;
