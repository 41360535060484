import { useSelector } from 'react-redux';
import TransactionActions from './TransactionActions';
import { TRANSACTION_STATE, TRANSACTION_TYPE } from '../utils/constants';
import { Tabs, Modal, Spin, Button } from 'antd';
import { JsonToTable } from 'react-json-to-table';
import { useHistory } from 'react-router-dom';

import { selectPayees } from '../store/modules/transaction';
import { format, parseISO } from 'date-fns';
const { TabPane } = Tabs;

export const TransactionInfo = ({
  visible,
  record,
  transactionInfo,
  fetchTransactionInfoSuccessResponse,
  loading,
  transactionAction,
  transactionInfoLoading,
  handleFetchTransactionInfo,
  handleCancel,
  tabKey,
  setTabKey,
}) => {
  const { fetchPayee } = useSelector(selectPayees);

  const history = useHistory();

  return (
    <Modal
      className="transaction-info__modal"
      title="Transaction Information"
      visible={visible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText="Close"
      maskClosable
      footer={[
        <Button
          key="link"
          onClick={() => {
            history.push({ pathname: '/home/print', state: transactionInfo });
          }}
        >
          Print
        </Button>,
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Spin spinning={!!fetchPayee}>
        {(transactionInfo.type === TRANSACTION_TYPE.buyfx.value ||
          transactionInfo.type === TRANSACTION_TYPE.sellfx.value ||
          transactionInfo.type === TRANSACTION_TYPE.remittance.value) && (
          <p className="notice">
            ✅ This {transactionInfo.type} instruction was OTP verified by {transactionInfo.email}{' '}
            on {format(parseISO(transactionInfo.created), 'dd/MM HH:mm aaa')} UTC.
          </p>
        )}
        <Tabs
          activeKey={tabKey}
          defaultActiveKey="1"
          onTabClick={(key) => {
            setTabKey(key);
            if (key === '2') handleFetchTransactionInfo(record);
          }}
        >
          <TabPane tab="Details" key="1">
            <JsonToTable json={transactionInfo} />
          </TabPane>
          {transactionInfo.status === TRANSACTION_STATE.PENDING &&
            !(
              transactionInfo.type === TRANSACTION_TYPE.credit.value ||
              transactionInfo.type === TRANSACTION_TYPE.fee.value
            ) && (
              <TabPane tab="Action" key="2">
                <TransactionActions
                  {...fetchTransactionInfoSuccessResponse?.data}
                  item={record}
                  loading={loading}
                  transactionAction={transactionAction}
                  transactionInfoLoading={transactionInfoLoading}
                />
              </TabPane>
            )}
        </Tabs>
      </Spin>
    </Modal>
  );
};
