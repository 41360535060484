export const FETCH_PAYEES_REQUESTED = 'FETCH_PAYEES_REQUESTED';
export const FETCH_PAYEE_SUCCESS = 'FETCH_PAYEE_SUCCESS';
export const FETCH_PAYEE_FAILED = 'FETCH_PAYEE_FAILED';

export const DELETE_PAYEE_REQUESTED = 'DELETE_PAYEE_REQUESTED';
export const DELETE_PAYEE_SUCCESS = 'DELETE_PAYEE_SUCCESS';
export const DELETE_PAYEE_FAILED = 'DELETE_PAYEE_FAILED';

export const ADD_PAYEE_INITIATED = 'ADD_PAYEE_INITIATED';
export const ADD_PAYEE_SUCCEEDED = 'ADD_PAYEE_SUCCEEDED';
export const ADD_PAYEE_FAILED = 'ADD_PAYEE_FAILED';

export const FETCH_PAYEE_USER_INFO_INITIATED = 'FETCH_PAYEE_USER_INFO_INITIATED';
export const FETCH_PAYEE_USER_INFO_SUCCEEDED = 'FETCH_PAYEE_USER_INFO_SUCCEEDED';
export const FETCH_PAYEE_USER_INFO_FAILED = 'FETCH_PAYEE_USER_INFO_FAILED';

export const CLEAR_PAYEE_USER_INFO_ERROR = 'CLEAR_PAYEE_USER_INFO_ERROR';
export const CLEAR_ADD_PAYEE_ERROR = 'CLEAR_ADD_PAYEE_ERROR';
export const RESET_ADD_PAYEE = 'RESET_ADD_PAYEE';
export const RESET_PAYEE = 'RESET_PAYEE';

export const GET_MORE_PAYEEDATA_INITIATED = 'GET_MORE_PAYEEDATA_INITIATED';
export const GET_MORE_PAYEEDATA_SUCCESSFUL = 'GET_MORE_PAYEEDATA_SUCCESSFUL';
export const GET_MORE_PAYEEDATA_FAILED = 'GET_MORE_PAYEEDATA_FAILED';

export const GET_PREV_PAYEEDATA_INITIATED = 'GET_PREV_PAYEEDATA_INITIATED';
export const GET_PREV_PAYEEDATA_SUCCESSFUL = 'GET_PREV_PAYEEDATA_SUCCESSFUL';
export const GET_PREV_PAYEEDATA_FAILED = 'GET_PREV_PAYEEDATA_FAILED';

export const GET_USER_EMAIL_PAYEE_INITIATED = 'GET_USER_EMAIL_PAYEE_INITIATED';
export const GET_USER_EMAIL_PAYEE_SUCCESFUL = 'GET_USER_EMAIL_PAYEE_SUCCESFUL';
export const GET_USER_EMAIL_PAYEE_FAILED = 'GET_USER_EMAIL_PAYEE_FAILED';

export const SET_PAYEE_PAGINATION_LIMIT = 'SET_PAYEE_PAGINATION_LIMIT';
