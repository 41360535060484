import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';

import AntPagination from '../../components/AntPagination';
import { getColumnSearchFilterProps } from '../../components/AntColumnSearchFilter';

import styles from './balance.module.css';
import {
  fetchBalanceInitiated,
  filterBalanceInitiated,
  getMoreBalanceInitiated,
  getPrevBalanceInitiated,
  setPaginationlimit,
} from '../../store/modules/balance/actions';
import { selectBalance, selectFilters } from '../../store/modules/balance';
import { formatMoney, openError } from '../../utils/libs';
import TableSketelon from '../../components/TableSkeleton';

export default function Balance() {
  const dispatch = useDispatch();
  const history = useHistory();
  const searchInput = useRef(null);

  const {
    Items: balance,
    loading,
    error,
    paginationLoading,
    cursorCache = [],
    nextCursor,
    limit,
  } = useSelector(selectBalance);
  const balanceFilters = useSelector(selectFilters);

  const getUserBalance = useCallback(
    (username = '', map = {}) => {
      const data = {
        ...balanceFilters,
        per_page: limit,
      };
      if (Object.keys(map).length && username) data.uid = map[username];
      dispatch(fetchBalanceInitiated(data));
    },
    [dispatch, limit, balanceFilters],
  );

  const setLimit = useCallback(
    (newLimit) => {
      dispatch(setPaginationlimit(newLimit));
    },
    [dispatch],
  );

  const filterBalance = useCallback(
    (filter) => {
      dispatch(filterBalanceInitiated({ ...filter }));
    },
    [dispatch],
  );
  const getMoreBalance = useCallback(() => {
    dispatch(
      getMoreBalanceInitiated({
        ...balanceFilters,
        per_page: limit,
        cursor: nextCursor,
      }),
    );
  }, [dispatch, nextCursor, limit, balanceFilters]);
  const getPrevBalance = useCallback(() => {
    dispatch(
      getPrevBalanceInitiated({
        ...balanceFilters,
        per_page: limit,
        cursor: cursorCache[cursorCache.length - 2],
      }),
    );
  }, [dispatch, cursorCache, limit, balanceFilters]);

  const balanceCol = [
    {
      title: 'username',
      dataIndex: 'email',
      key: 'username',
      fixed: 'left',
      width: 200,
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortDirections: ['descend', 'ascend'],
      defaultFilteredValue: balanceFilters?.username || [],
      ...getColumnSearchFilterProps('username', searchInput),
    },
    {
      title: 'currency',
      dataIndex: 'currency',
      key: 'currency',
      width: 120,
      defaultFilteredValue: balanceFilters?.currency || [],
      filters: [
        {
          text: 'AUD',
          value: 'AUD',
        },
        {
          text: 'CAD',
          value: 'CAD',
        },
        {
          text: 'CNY',
          value: 'CNY',
        },
        {
          text: 'EUR',
          value: 'EUR',
        },
        {
          text: 'GBP',
          value: 'GBP',
        },
        {
          text: 'HKD',
          value: 'HKD',
        },
        {
          text: 'IDR',
          value: 'IDR',
        },
        {
          text: 'JPY',
          value: 'JPY',
        },
        {
          text: 'KRW',
          value: 'KRW',
        },
        {
          text: 'MYR',
          value: 'MYR',
        },
        {
          text: 'SGD',
          value: 'SGD',
        },
        {
          text: 'THB',
          value: 'THB',
        },
        {
          text: 'TWD',
          value: 'TWD',
        },
        {
          text: 'USD',
          value: 'USD',
        },
      ],
      onFilter: (value, record) => record.currency === value,
    },
    {
      title: 'available',
      dataIndex: 'available',
      key: 'available',
      width: 150,
      sorter: (a, b) => a.available - b.available,
      sortDirections: ['descend', 'ascend'],
      render: (item) => <>{item && formatMoney(item)}</>,
    },
    {
      title: 'ledger',
      dataIndex: 'ledger',
      key: 'ledger',
      width: 150,
      sorter: (a, b) => a.ledger - b.ledger,
      sortDirections: ['descend', 'ascend'],
      render: (item) => <>{item && formatMoney(item)}</>,
    },
    {
      title: 'last_update',
      dataIndex: 'last_updated',
      key: 'last_update',
      width: 250,
      sorter: (a, b) => Date.parse(a.last_updated) - Date.parse(b.last_updated),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
  ];

  useEffect(() => {
    Auth.currentSession()
      .then(() => {})
      .catch(() => {
        openError('Sign-in expired', 'please login again');
        history.push('/login');
      });
  }, [history]);

  useEffect(() => {
    getUserBalance();
  }, [getUserBalance]);

  useEffect(() => {
    error && openError('', JSON.stringify(error));
  }, [error]);

  const tableloading = loading || paginationLoading;

  return (
    <div>
      <h1>Balance table</h1>
      {loading ? (
        <TableSketelon columns={balanceCol} />
      ) : (
        <Table
          className={styles.center}
          loading={tableloading}
          dataSource={balance}
          columns={balanceCol}
          style={{ width: '100%' }}
          pagination={false}
          onChange={(_, filters) => {
            filterBalance(
              Object.keys(filters)
                .filter((item) => filters[item])
                .reduce((acc, item) => ({ ...acc, [item]: filters[item] }), {}),
            );
          }}
        />
      )}
      <AntPagination
        pageSize={limit}
        isLoading={tableloading}
        onNextBtnClick={getMoreBalance}
        onPrevBtnClick={getPrevBalance}
        onChange={(_, size) => setLimit(size)}
        disableNext={!nextCursor}
        disablePrev={cursorCache.length <= 1}
      />
    </div>
  );
}
