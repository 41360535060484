import { all } from 'redux-saga/effects';
import { GetBalance, GetMoreBalance, GetPrevBalance } from './modules/balance';
import {
  GetTransaction,
  ConfirmTransactionAction,
  AddTransaction,
  GetUserInfo,
  ClearErrSuccess,
  TransactionSuccess,
  TransactionFailure,
  TransactionConfirmFailure,
  TransactionConfirmSuccess,
  GetTransactionInfo,
  ClearFetchedTransactionInfo,
  GetMoreTransaction,
  GetPrevTransaction,
  Get2Transaction,
  GetPayeeInformation,
  SetUseLedgerOption,
} from './modules/transaction';
import { GetRate } from './modules/rate';
import { GetAuth } from './modules/auth';
import {
  GetCurrency,
  UpdateCurrency,
  AddCurrency,
  RemoveCurrency,
  ClearMsgs,
} from './modules/currency';
import {
  GetPayee,
  DeletePayee,
  AddPayeeSaga,
  GetUserEmailInfo,
  GetMorePayeeData,
  GetPrevPayeeTransaction,
  GetUserPayee,
} from './modules/payee';
import { HaltTrade, FetchHaltTrade } from './modules/settings';

export default function* rootSaga() {
  yield all([
    GetBalance(),
    GetTransaction(),
    ConfirmTransactionAction(),
    GetRate(),
    GetAuth(),
    AddTransaction(),
    GetUserInfo(),
    ClearErrSuccess(),
    TransactionSuccess(),
    TransactionFailure(),
    TransactionConfirmSuccess(),
    TransactionConfirmFailure(),
    GetCurrency(),
    UpdateCurrency(),
    AddCurrency(),
    RemoveCurrency(),
    ClearMsgs(),
    GetTransactionInfo(),
    ClearFetchedTransactionInfo(),
    GetMoreTransaction(),
    GetPrevTransaction(),
    GetMoreBalance(),
    GetPrevBalance(),
    Get2Transaction(),
    DeletePayee(),
    GetPayee(),
    AddPayeeSaga(),
    GetUserEmailInfo(),
    GetMorePayeeData(),
    GetPrevPayeeTransaction(),
    GetPayeeInformation(),
    GetUserPayee(),
    SetUseLedgerOption(),
    HaltTrade(),
    FetchHaltTrade(),
  ]);
}
