import { Switch } from 'antd';
import styles from './index.module.css';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setTradeAlert } from '../store/modules/settings/actions';
import { selectMuted } from '../store/modules/settings';

function TradeAlerts() {
  const muted = useSelector(selectMuted);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('trade_alert_muted', String(muted));
  }, [muted]);

  return (
    <div className={styles.mb_10}>
      <span className={styles.label}>New Trade Alerts:</span>{' '}
      <Switch
        className={muted ? styles.bg_gray : styles.bg_primary}
        checkedChildren="Alert On"
        unCheckedChildren="Alert Off"
        defaultChecked={!muted}
        onChange={() => dispatch(setTradeAlert(!muted))}
      />
    </div>
  );
}

export default TradeAlerts;
