import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Row, Col, Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPayeeInitiated,
  fetchUserInfoInitiated,
  clearUserInfo,
  clearAddPayeeError,
  resetAddPayee,
} from '../store/modules/payee/actions';
import { selectPayee } from '../store/modules/payee';
import NumberFormat from 'react-number-format';
import { openError, openNotification } from '../utils/libs';
import styles from './index.module.css';

const AddPayeeModal = ({ isModalVisible, handleCancel }) => {
  const dispatch = useDispatch();
  const [formError, setFormErrors] = useState(null);
  const [form] = Form.useForm();
  const [userInfoValidation, setUserInfoValidation] = useState({
    validateStatus: '',
    message: '',
    userEmail: '',
  });

  const { userInfo, userInfoError, addPayeeLoading, addPayeeError, newPayeeData } =
    useSelector(selectPayee);

  const addPayeeCallback = useCallback(
    (data) => {
      dispatch(addPayeeInitiated(data));
    },
    [dispatch],
  );

  const fetchUserInfoCallback = useCallback(
    (data) => {
      dispatch(fetchUserInfoInitiated(data));
    },
    [dispatch],
  );

  const resetUserInfo = useCallback(() => {
    dispatch(clearUserInfo());
  }, [dispatch]);

  const resetAddPayeeError = useCallback(() => {
    dispatch(clearAddPayeeError());
  }, [dispatch]);

  const reset = useCallback(() => {
    dispatch(resetAddPayee());
  }, [dispatch]);

  const clearState = useCallback(() => {
    form.resetFields();
    setUserInfoValidation({
      validateStatus: '',
      message: '',
      userEmail: '',
    });
  }, [form]);

  const handleModalClose = useCallback(() => {
    if (!addPayeeLoading) {
      reset();
      clearState();
      handleCancel();
    }
  }, [addPayeeLoading, clearState, handleCancel, reset]);

  const handleFormChange = useCallback(() => {
    const formErrors = form.getFieldsError();
    const errors = formErrors.reduce((acc, { name, errors }) => {
      return { ...acc, ...(errors.length ? { [name[0]]: errors } : {}) };
    }, {});

    setFormErrors(Object.keys(errors).length ? errors : null);
  }, [form]);

  const handleOk = (formValues) => {
    const payeePayload = {
      ...formValues,
      uid: userInfo.user.uid,
    };
    addPayeeCallback(payeePayload);
  };

  const checkForUserInfo = (e) => {
    const userEmail = e.target.value.trim();
    if (!['', userInfoValidation.userEmail].includes(userEmail)) {
      setUserInfoValidation({
        validateStatus: 'validating',
        message: '',
        userEmail,
      });

      fetchUserInfoCallback({ username: userEmail });
    }
  };

  useEffect(() => {
    if (userInfoError) {
      setUserInfoValidation((prevState) => ({
        ...prevState,
        validateStatus: 'error',
        message: 'Username not found, please double check and try again.',
      }));
      resetUserInfo();
    } else if (userInfo.user) {
      setUserInfoValidation((prevState) => ({
        ...prevState,
        validateStatus: 'success',
        message: '',
      }));
    }
  }, [resetUserInfo, userInfo.user, userInfoError]);

  useEffect(() => {
    if (newPayeeData) {
      openNotification('', 'Payee added successfully');
      handleModalClose();
    }
  }, [handleModalClose, newPayeeData]);

  useEffect(() => {
    if (addPayeeError) {
      openError('', addPayeeError);
      resetAddPayeeError();
    }
  }, [addPayeeError, resetAddPayeeError]);

  return (
    <>
      <Modal
        title="Add Payee"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={false}
        width={1000}
        style={{ top: 20 }}
      >
        <Form
          onFieldsChange={handleFormChange}
          form={form}
          onFinish={handleOk}
          layout="horizontal"
          name="addpayee"
          size={'middle'}
          requiredMark={true}
        >
          <Form.Item
            name="user_email"
            label="User Email:"
            hasFeedback
            validateStatus={userInfoValidation.validateStatus}
            help={userInfoValidation.message}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 7, push: 0 }}
            rules={[
              {
                required: true,
                message: "Please enter user's email",
              },
            ]}
          >
            <Input onBlur={checkForUserInfo} />
          </Form.Item>
          <Row>
            <Col span={10}>
              <Form.Item
                name="name"
                label="Name:"
                rules={[
                  {
                    required: true,
                    message: "Please enter payee's name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email:"
                rules={[
                  {
                    required: true,
                    message: "Please enter Payee's email",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Phone:"
                rules={[
                  {
                    required: true,
                    message: 'Please provide a value for this field',
                  },
                ]}
              >
                <NumberFormat customInput={Input} allowNegative={false} prefix="+" />
              </Form.Item>
              <Form.Item
                name="bank_account"
                label="Bank Account:"
                rules={[
                  {
                    required: true,
                    message: 'Please provide a value for this field',
                  },
                ]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="bank_name"
                label="Bank Name:"
                rules={[
                  {
                    required: true,
                    message: 'Please provide a value for this field',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="bank_address"
                label="Bank Address:"
                rules={[
                  {
                    required: true,
                    message: 'Please provide a value for this field',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={10} style={{ marginLeft: '30px' }}>
              <Form.Item
                name="branch_name"
                label="Branch Name:"
                rules={[
                  {
                    required: true,
                    message: 'Please provide a value for this field',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="swift_code"
                label="Swift Code:"
                rules={[
                  {
                    required: true,
                    message: 'Please provide a value for this field',
                  },
                ]}
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="nickname" label="Nickname:">
                <Input />
              </Form.Item>
              <Form.Item name="id_number" label="ID number:">
                <Input />
              </Form.Item>
              <Form.Item name="address" label="address:">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.add_payee_btn_bucket}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button onClick={handleModalClose} disabled={addPayeeLoading}>
                Cancel
              </Button>
              <Button
                disabled={!!formError}
                loading={addPayeeLoading}
                type="primary"
                htmlType="submit"
              >
                Add
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default React.memo(AddPayeeModal);
