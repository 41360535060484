import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import Amplify from 'aws-amplify';

import { fetchSignInInitiated } from '../../store/modules/auth/actions';
import { selectAuth } from '../../store/modules/auth';
import awsconfig from '../../aws-exports';
import styles from './login.module.css';

Amplify.configure(awsconfig);

export default function Login(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { user, loading, error } = useSelector(selectAuth);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const signInUser = useCallback(
    (username, password) => {
      dispatch(fetchSignInInitiated({ username, password }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (error) {
      message.error('Sign in failed. ' + error.message);

      return;
    }

    if (user) {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return history.push('/reset-password');
      }

      message.success('Welcome! ' + user.username);
      history.push('/home/transaction');
    }
  }, [user, error, history]);

  return (
    <div className={styles.content}>
      <h1>Please login</h1>
      <div className={styles.center}>
        <Form {...layout} name="basic" initialValues={{ remember: true }}>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input onChange={(e) => setUsername(e.target.value)} />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password onChange={(e) => setPassword(e.target.value)} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              style={{ width: '100%' }}
              loading={loading}
              shape="round"
              type="primary"
              htmlType="submit"
              onClick={() => {
                signInUser(username, password);
              }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 12 },
};
