import request from '../../../utils/request';

const getCurrency = async (params) => {
  return await request.get('/v5/nk/query/schema', { params });
};

const putCurrency = async (data) => {
  return await request.post('/v5/nk/pricing_schema', { data });
};

const addCurrency = async (data) => {
  return await request.put('/v5/nk/add_currency', { data });
};

const removeCurrency = async (data) => {
  return await request.delete('/v5/nk/remove_currency', { data });
};

const actions = {
  getCurrency,
  putCurrency,
  removeCurrency,
  addCurrency,
};

export default actions;
