import { useEffect, useState } from 'react';
import { Input, InputNumber, Checkbox, Form, Row, Col, Divider } from 'antd';
import styles from './currenct-forms.module.css';

const SPREAD_2_LABEL = 'Premium outside of office hours (8:30 - 18:00):';

export const EditCurrencyForm = ({ form, initialValues, data }) => {
  const [isSchemaDisable, setIsSchemaDisable] = useState(false);

  useEffect(() => {
    if (data) {
      form.setFieldsValue(initialValues);
      setIsSchemaDisable(initialValues.overwrite);
    }
  }, [form, initialValues, data]);

  const valueValidation = (rule, value, callback) => {
    if (!isSchemaDisable) {
      const { spread_1, spread_2, spread_3, overwrite } = form.getFieldValue();
      if (!spread_1 && !spread_2 && !spread_3 && !overwrite) {
        return callback('Requiring at least one of the field value');
      }
      if (Number.isNaN(spread_1) || Number.isNaN(spread_2) || Number.isNaN(spread_3)) {
        return callback('Please provide the value as a number');
      }
    }
    callback();
  };
  const clearFieldErrors = (_changedValues, allValues) => {
    const updatedFields = Object.keys(allValues)
      .filter((name) => form.getFieldError(name).length)
      .map((name) => ({ name, errors: [] }));
    form.setFields(updatedFields);
  };

  return (
    <div>
      <h3 className="schema-name-heading">
        {initialValues.currency} NK {initialValues.type.toUpperCase()} (CUSTOMER{' '}
        {initialValues.rtype.toUpperCase()})
      </h3>

      <Form
        form={form}
        layout="horizontal"
        name="editCurrencyForm"
        initialValues={initialValues}
        onValuesChange={clearFieldErrors}
      >
        <Form.Item hidden={true} label="currency" name="currency">
          <Input />
        </Form.Item>
        <Form.Item hidden={true} label="type" name="type">
          <Input />
        </Form.Item>
        <Form.Item
          colon={false}
          label="Premium normal:"
          name="spread_1"
          rules={[
            {
              validator: valueValidation,
            },
          ]}
        >
          <InputNumber className={styles.formInNumber} disabled={isSchemaDisable} />
        </Form.Item>
        <Form.Item
          colon={false}
          label={SPREAD_2_LABEL}
          name="spread_2"
          style={{ whiteSpace: 'break-spaces' }}
          rules={[
            {
              validator: valueValidation,
            },
          ]}
        >
          <InputNumber className={styles.formInNumber} disabled={isSchemaDisable} />
        </Form.Item>
        <Form.Item
          colon={false}
          label="Premium on weekends and public holidays:"
          name="spread_3"
          rules={[
            {
              validator: valueValidation,
            },
          ]}
        >
          <InputNumber className={styles.formInNumber} disabled={isSchemaDisable} />
        </Form.Item>
        <Form.Item
          className={`${styles.overwriteCheckbox} ${styles.editForm}`}
          valuePropName="checked"
          name="overwrite"
        >
          <Checkbox
            onChange={(e) => {
              form.setFields(
                form.getFieldsError().map((error) => ({ name: error.name, errors: [] })),
              );
              form.setFieldsValue({ override: null });
              setIsSchemaDisable(e.target.checked);
            }}
          >
            Overwrite
          </Checkbox>
        </Form.Item>

        <Form.Item
          colon={false}
          name="override"
          rules={[
            {
              required: isSchemaDisable,
              message: 'Please provide the value of Overwrite',
            },
          ]}
        >
          <InputNumber className={styles.formInNumber} disabled={!isSchemaDisable} />
        </Form.Item>
      </Form>
    </div>
  );
};

export const AddCurrencyForm = ({ form }) => {
  const [isBuySchemaDisable, setIsBuySchemaDisable] = useState(false);
  const [isSellSchemaDisable, setIsSellSchemaDisable] = useState(false);

  const initialValues = {
    currency: '',
    buySchema: { spread_1: '', spread_2: '', spread_3: '', override: null, overwrite: false },
    sellSchema: { spread_1: '', spread_2: '', spread_3: '', override: null, overwrite: false },
  };

  return (
    <div>
      <Form
        form={form}
        layout="horizontal"
        name="addCurrency"
        size={'middle'}
        initialValues={initialValues}
        requiredMark={false}
      >
        <Form.Item
          colon={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 7, push: 0 }}
          name="currency"
          label="Currency Code:"
          rules={[
            {
              required: true,
              message: 'Please provide the value of the field',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Row gutter={8}>
          <Col span={11}>
            <h3 className="schema-name-heading">NK Buy (Customer Sell)</h3>
            <Form.Item
              colon={false}
              label="Premium normal:"
              name={['buySchema', 'spread_1']}
              rules={[
                {
                  required: true,
                  message: 'Please provide the value of the field',
                },
              ]}
            >
              <InputNumber className={styles.formInNumber} />
            </Form.Item>
            <Form.Item
              colon={false}
              label={SPREAD_2_LABEL}
              name={['buySchema', 'spread_2']}
              rules={[
                {
                  required: true,
                  message: 'Please provide the value of the field',
                },
              ]}
            >
              <InputNumber className={styles.formInNumber} />
            </Form.Item>
            <Form.Item
              colon={false}
              label="Premium on weekends and public holidays:"
              name={['buySchema', 'spread_3']}
              rules={[
                {
                  required: true,
                  message: 'Please provide the value of the field',
                },
              ]}
            >
              <InputNumber className={styles.formInNumber} />
            </Form.Item>

            <Form.Item
              className={`${styles.overwriteCheckbox} ${styles.addForm}`}
              name={['buySchema', 'overwrite']}
              valuePropName="checked"
            >
              <Checkbox
                onChange={(e) => {
                  setIsBuySchemaDisable(e.target.checked);
                }}
              >
                Overwrite
              </Checkbox>
            </Form.Item>

            <Form.Item
              colon={false}
              name={['buySchema', 'override']}
              rules={[
                {
                  required: isBuySchemaDisable,
                  message: 'Please provide the value of Overwrite:',
                },
              ]}
            >
              <InputNumber className={styles.formInNumber} disabled={!isBuySchemaDisable} />
            </Form.Item>
          </Col>

          <Col>
            <Divider type="vertical" className={styles.divederHeight} />
          </Col>

          <Col span={11}>
            <h3 className="schema-name-heading">NK Sell (Customer Buy)</h3>
            <Form.Item
              colon={false}
              label="Premium normal:"
              name={['sellSchema', 'spread_1']}
              rules={[
                {
                  required: true,
                  message: 'Please provide the value of the field',
                },
              ]}
            >
              <InputNumber className={styles.formInNumber} />
            </Form.Item>
            <Form.Item
              colon={false}
              label={SPREAD_2_LABEL}
              name={['sellSchema', 'spread_2']}
              rules={[
                {
                  required: true,
                  message: 'Please provide the value of the field',
                },
              ]}
            >
              <InputNumber className={styles.formInNumber} />
            </Form.Item>
            <Form.Item
              colon={false}
              label="Premium on weekends and public holidays:"
              name={['sellSchema', 'spread_3']}
              rules={[
                {
                  required: true,
                  message: 'Please provide the value of the field',
                },
              ]}
            >
              <InputNumber className={styles.formInNumber} />
            </Form.Item>

            <Form.Item
              className={`${styles.overwriteCheckbox} ${styles.addForm}`}
              name={['sellSchema', 'overwrite']}
              valuePropName="checked"
            >
              <Checkbox
                onChange={(e) => {
                  setIsSellSchemaDisable(e.target.checked);
                }}
              >
                Overwrite
              </Checkbox>
            </Form.Item>

            <Form.Item
              colon={false}
              name={['sellSchema', 'override']}
              rules={[
                {
                  required: isSellSchemaDisable,
                  message: 'Please provide the value of Overwrite:',
                },
              ]}
            >
              <InputNumber className={styles.formInNumber} disabled={!isSellSchemaDisable} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
