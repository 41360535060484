import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrency } from '../store/modules/currency';
import {
  fetchCurrencyInitiated,
  updateCurrencyInitiated,
  addCurrencyInitiated,
} from '../store/modules/currency/actions';
import { Modal, Form, Spin } from 'antd';
import { AddCurrencyForm, EditCurrencyForm } from './CurrenyForms';

export const CurrencyModalForm = ({ type, title, visible, setIsVisible, query }) => {
  const dispatch = useDispatch();
  const { schema: currency, loading } = useSelector(selectCurrency);

  const [form] = Form.useForm();

  const getCurrency = useCallback(
    (data) => {
      dispatch(fetchCurrencyInitiated(data));
    },
    [dispatch],
  );

  const updateCurrency = useCallback(
    async (data) => {
      dispatch(updateCurrencyInitiated(data));
    },
    [dispatch],
  );

  const createCurrency = useCallback(
    async (data) => {
      await dispatch(addCurrencyInitiated(data));
    },
    [dispatch],
  );

  useEffect(() => {
    if (query) {
      getCurrency(query);
    }
  }, [query, getCurrency]);

  const update = async (values) => {
    updateCurrency(values);
  };

  const create = async (values) => {
    createCurrency(values);
    form.resetFields();
  };

  const handleSubmitForm = (form) => () => {
    form
      .validateFields()
      .then((values) => {
        type === 'edit' ? update(values) : create(values);
      })
      .catch((info) => {});
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const CurrencyForms = {
    edit: () => {
      return (
        <EditCurrencyForm
          form={form}
          initialValues={{
            ...query,
            ...currency,
            overwrite: !!currency?.override,
          }}
          data={currency}
        />
      );
    },
    add: () => {
      return <AddCurrencyForm form={form} />;
    },
  };

  const modalWidth = type === 'add' ? 930 : 520;

  return (
    <Modal
      className={`${type}-modal`}
      title={title}
      visible={visible}
      cancelText="Cancel"
      okText="Save"
      onOk={handleSubmitForm(form)}
      okButtonProps={{ className: 'btn-submit' }}
      onCancel={handleCancel}
      width={modalWidth}
    >
      <Spin size={'large'} spinning={!!loading}>
        {CurrencyForms[type]()}
      </Spin>
    </Modal>
  );
};
