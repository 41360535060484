import * as TYPES from './types';

export const fetchRateInitiated = (data) => ({
  type: TYPES.FETCH_RATE_INITIATED,
  payload: data,
});

export const fetchRateSucceeded = (data) => ({
  type: TYPES.FETCH_RATE_SUCCEEDED,
  payload: data,
});

export const fetchRateFailed = (data) => ({
  type: TYPES.FETCH_RATE_FAILED,
  payload: data,
});
