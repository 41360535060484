import { notification, Button } from 'antd';

const warningMsg = `The following trade was unable to be written to the NK trading ledger, although it was successfully confirmed on RFX. Please manually record this trade information.\n`;

export const orderError = (msg) => {
  const key = Date.now();

  const btn = (
    <Button type="primary" onClick={() => notification.close(key)}>
      OK
    </Button>
  );

  notification.warn({
    btn,
    key,
    duration: 0,
    closeIcon: <></>,
    message: 'Warning',
    description: warningMsg + msg,
  });
};
