import {
  FETCH_PAYEES_REQUESTED,
  DELETE_PAYEE_REQUESTED,
  ADD_PAYEE_INITIATED,
  DELETE_PAYEE_FAILED,
  ADD_PAYEE_SUCCEEDED,
  ADD_PAYEE_FAILED,
  DELETE_PAYEE_SUCCESS,
  FETCH_PAYEE_USER_INFO_INITIATED,
  FETCH_PAYEE_USER_INFO_SUCCEEDED,
  FETCH_PAYEE_USER_INFO_FAILED,
  CLEAR_PAYEE_USER_INFO_ERROR,
  CLEAR_ADD_PAYEE_ERROR,
  RESET_ADD_PAYEE,
  RESET_PAYEE,
  GET_MORE_PAYEEDATA_INITIATED,
  GET_MORE_PAYEEDATA_SUCCESSFUL,
  GET_MORE_PAYEEDATA_FAILED,
  GET_PREV_PAYEEDATA_INITIATED,
  GET_PREV_PAYEEDATA_SUCCESSFUL,
  GET_PREV_PAYEEDATA_FAILED,
  SET_PAYEE_PAGINATION_LIMIT,
  FETCH_PAYEE_SUCCESS,
  FETCH_PAYEE_FAILED,
  GET_USER_EMAIL_PAYEE_INITIATED,
  GET_USER_EMAIL_PAYEE_SUCCESFUL,
  GET_USER_EMAIL_PAYEE_FAILED,
} from './types';

export const fetchPayee = (data) => {
  return {
    type: FETCH_PAYEES_REQUESTED,
    payload: data,
  };
};

export const fetchPayeeSuccessful = (data) => ({
  type: FETCH_PAYEE_SUCCESS,
  payload: data,
});

export const fetchPayeeFailed = (data) => ({
  type: FETCH_PAYEE_FAILED,
  payload: data,
});

export const deletePayee = (data) => {
  return {
    type: DELETE_PAYEE_REQUESTED,
    payload: data,
  };
};

export const deletePayeeSuccess = (payeeId) => ({
  type: DELETE_PAYEE_SUCCESS,
  payload: payeeId,
});

export const deletePayeeFailed = (data) => ({
  type: DELETE_PAYEE_FAILED,
  payload: data,
});

export const addPayeeInitiated = (data) => {
  return {
    type: ADD_PAYEE_INITIATED,
    payload: data,
  };
};

export const addPayeeSucceeded = (data) => ({
  type: ADD_PAYEE_SUCCEEDED,
  payload: data,
});

export const addPayeeFailed = (data) => ({
  type: ADD_PAYEE_FAILED,
  payload: data,
});

export const fetchUserInfoInitiated = (data) => {
  return {
    type: FETCH_PAYEE_USER_INFO_INITIATED,
    payload: data,
  };
};

export const fetchUserInfoSucceeded = (data) => ({
  type: FETCH_PAYEE_USER_INFO_SUCCEEDED,
  payload: data,
});

export const fetchUserInfoFailed = (data) => ({
  type: FETCH_PAYEE_USER_INFO_FAILED,
  payload: data,
});

export const clearUserInfo = () => ({
  type: CLEAR_PAYEE_USER_INFO_ERROR,
});
export const clearAddPayeeError = () => ({
  type: CLEAR_ADD_PAYEE_ERROR,
});
export const resetAddPayee = () => ({
  type: RESET_ADD_PAYEE,
});
export const resetPayee = () => ({
  type: RESET_PAYEE,
});

export const getMorePayeeDataInitiated = (payload) => {
  return {
    type: GET_MORE_PAYEEDATA_INITIATED,
    payload,
  };
};

export const getMorePayeeDataSuccesful = (payload) => ({
  type: GET_MORE_PAYEEDATA_SUCCESSFUL,
  payload,
});

export const getMorePayeeDataFailed = (data) => ({
  type: GET_MORE_PAYEEDATA_FAILED,
  payload: data,
});

export const getPrevPayeeDataInitated = (payload) => ({
  type: GET_PREV_PAYEEDATA_INITIATED,
  payload,
});

export const getPrevPayeeDataSuccessful = (data) => ({
  type: GET_PREV_PAYEEDATA_SUCCESSFUL,
  payload: data,
});

export const getPrevPayeeDataFailed = (payload) => ({
  type: GET_PREV_PAYEEDATA_FAILED,
  payload,
});

export const setPaginationlimit = (payload) => ({
  type: SET_PAYEE_PAGINATION_LIMIT,
  payload,
});

export const getUserPayeeInitiated = (data) => ({
  type: GET_USER_EMAIL_PAYEE_INITIATED,
  payload: data,
});

export const getUserPayeeSuccessful = (data) => ({
  type: GET_USER_EMAIL_PAYEE_SUCCESFUL,
  payload: data,
});

export const getUserPayeeFailed = (data) => ({
  type: GET_USER_EMAIL_PAYEE_FAILED,
  payload: data,
});
