import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import awsconfig from '../../../aws-exports';

Amplify.configure(awsconfig);

const signIn = async ({ username, password }) => {
  const user = await Auth.signIn(username, password);

  return user;
};

const resetUserPassword = async ({ user, password }) => {
  const response = await Auth.completeNewPassword(user, password);

  return response;
};

const actions = {
  signIn,
  resetUserPassword,
};

export default actions;
