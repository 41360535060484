import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import API from './requests';

import {
  fetchTransactionFailed,
  fetchTransactionSucceeded,
  transactionConfirmationFailed,
  transactionConfirmationSucceeded,
  addTransactionFailed,
  addTransactionSucceeded,
  fetchUserInfoSucceeded,
  fetchUserInfoFailed,
  fetchTransactionInfoSucceeded,
  fetchTransactionInfoFailed,
  getMoreTransactionSucceeded,
  getMoreTransactionFailed,
  getPrevTransactionSucceeded,
  getPrevTransactionFailed,
  getTransactionSucceeded,
  getTransactionFailed,
  fetchPayeeSucceeded,
  fetchPayeeFailed,
} from './actions';
import {
  paginationDefaultLimit,
  TRANSACTION_TYPE,
  NonStandardCurrencyLookup,
} from '../../../utils/constants';
import { formatNKDate, formatNKAmount } from '../../../utils/libs';

import {
  FETCH_TRANSACTION_FAILED,
  FETCH_TRANSACTION_SUCCEEDED,
  FETCH_TRANSACTION_INITIATED,
  TRANSACTION_CONFIRMATION_INITIATED,
  TRANSACTION_CONFIRMATION_FAILED,
  TRANSACTION_CONFIRMATION_SUCCEEDED,
  ADD_TRANSACTION_FAILED,
  ADD_TRANSACTION_SUCCEEDED,
  ADD_TRANSACTION_INITIATED,
  FETCH_USER_INFO_INITIATED,
  FETCH_USER_INFO_SUCCEEDED,
  FETCH_USER_INFO_FAILED,
  CLEAR_USER_INFO_ERROR_SUCCESS,
  FETCH_TRANSACTION_INFO_INITIATED,
  FETCH_TRANSACTION_INFO_SUCCEEDED,
  FETCH_TRANSACTION_INFO_FAILED,
  CLEAR_FETCH_TRANSACTION_INFO,
  FILTER_TRANSACTION_INITIATED,
  SET_USE_LEDGER_OPTION,
  GET_MORE_TRANSACTION_INITIATED,
  GET_MORE_TRANSACTION_SUCCEEDED,
  GET_MORE_TRANSACTION_FAILED,
  GET_PREVIOUS_TRANSACTION_INITIATED,
  GET_PREVIOUS_TRANSACTION_SUCCEEDED,
  GET_PREVIOUS_TRANSACTION_FAILED,
  SET_TRANSACTION_LIMIT,
  GET_TRANSACTION_INITIATED,
  GET_TRANSACTION_SUCCEEDED,
  GET_TRANSACTION_FAILED,
  GET_PAYEE_INFO_INITIATED,
  GET_PAYEE_INFO_SUCCEEDED,
  GET_PAYEE_INFO_FAILED,
} from './types';

import { orderError } from '../../../components/OrderAlert';

export const INITIAL_STATE = {
  Items: [],
  record: {},
  filters: {},
  nextCursor: '',
  cursorCache: [''],
  cursor: '',
  limit: paginationDefaultLimit,
  paginationLoading: false,
  loading: false,
  initialLoading: false,
  transactionInfoLoading: false,
  initialTransactionInfoLoading: false,
  transactionInfoOnClickLoading: false,
  payees: {},
  useLedger: true,
};

export function* ClearErrSuccess() {
  yield takeLatest(CLEAR_USER_INFO_ERROR_SUCCESS, () => {});
}

export function* ClearFetchedTransactionInfo() {
  yield takeLatest(CLEAR_FETCH_TRANSACTION_INFO, () => {});
}

export function* getUserInfoRequest(action) {
  try {
    const data = yield call(API.getUserInfo, action.payload);

    yield put(fetchUserInfoSucceeded(data));
  } catch (e) {
    yield put(fetchUserInfoFailed({ error: e, success: false }));
  }
}

export function* getTransactionRequest(action) {
  try {
    const data = yield call(API.getTransactionList, action.payload);

    yield put(fetchTransactionSucceeded(data));
  } catch (e) {
    yield put(fetchTransactionFailed(e));
  }
}

function callNkOrderApi(action) {
  const transactionRequest = action.payload;
  // useLedger & transaction buy/sell?
  if (
    (transactionRequest.transaction_type === TRANSACTION_TYPE.sellfx.value ||
      transactionRequest.transaction_type === TRANSACTION_TYPE.buyfx.value) &&
    transactionRequest.useLedger
  ) {
    const { transactionUserDetails, transactionInfo, adminUser } = transactionRequest;

    const { foreign_currency } = transactionInfo;

    // if no mapped currency found, send the current currency as it is.
    const mappedForeignCurrency = NonStandardCurrencyLookup[foreign_currency] || foreign_currency;

    const orderRefNumber = transactionInfo.transaction_id.split('Z-')[1];

    const orderPayload = {
      orderRefNumber,
      status: 'confirmed',
      transactionType: transactionInfo.type,
      orderType: transactionInfo.type,
      total: formatNKAmount(transactionInfo.local_amount),
      txDate: formatNKDate(transactionInfo.created),
      charges: transactionInfo.fee,
      customerName: transactionUserDetails.company_name,
      customerEmail: transactionUserDetails.email,
      customerPhone: transactionUserDetails.phone_number,
      profileCode: 'PECUTUS',
      createdBy: adminUser,
      orderItems: [
        {
          ccy: mappedForeignCurrency,
          amount: formatNKAmount(transactionInfo.foreign_amount),
          rate: transactionInfo.rate,
          refNumber: transactionInfo.transaction_id,
          subtotal: formatNKAmount(transactionInfo.local_amount),
        },
      ],
    };
    return API.orderTransaction(orderPayload).catch((err) => {
      delete action.payload.useLedger;
      const msg = err.message + ' ' + JSON.stringify(action.payload);
      orderError(msg);
      console.error('err', err);
    });
  }
}

function feeTx(transactionInfo) {
  const { email, fee } = transactionInfo;
  return {
    email,
    local_amount: typeof fee === 'string' ? parseFloat(fee) : fee,
    local_currency: 'HKD',
    type: TRANSACTION_TYPE.fee.value,
  };
}

export function* transactionConfirmationRequest(action) {
  const { payload } = action;
  try {
    let data = yield call(API.confirmTransaction, payload);

    // ok request
    if (data.status === 200 && payload.transaction_status === 'confirmed') {
      // call order api
      data = callNkOrderApi(action);
    }

    yield put(
      transactionConfirmationSucceeded({
        ...payload,
        ...data,
      }),
    );

    if (
      payload.transaction_status === 'confirmed' &&
      payload.transactionInfo?.type === TRANSACTION_TYPE.remittance.value &&
      data.success
    ) {
      yield call(API.addTransaction, feeTx(payload.transactionInfo));
    }

    payload.setTransactionInfo({ ...payload.transactionInfo, status: payload.transaction_status });
  } catch (e) {
    console.log(e);
    yield put(transactionConfirmationFailed({ ...payload, ...e }));
  }
}

export function* addTransactionRequest(action) {
  try {
    let data = {};
    if (action.payload.offline_transaction_checkbox) {
      data = yield call(API.addTransactionOffline, action.payload);
    } else {
      data = yield call(API.addTransaction, action.payload);
    }

    yield put(
      addTransactionSucceeded({ ...data, successCallback: action.payload.successCallback }),
    );
  } catch (e) {
    yield put(addTransactionFailed({ ...e.data, failureCallback: action.payload.failureCallback }));
  }
}

export function* getMoreTransactionRequest(action) {
  try {
    const data = yield call(API.getTransactionList, action.payload);

    yield put(getMoreTransactionSucceeded(data));
  } catch (e) {
    yield put(getMoreTransactionFailed(e));
  }
}

export function* getPayeeInformationRequest(action) {
  try {
    const data = yield call(API.getPayeeInfo, action.payload);

    yield put(fetchPayeeSucceeded(data));
  } catch (e) {
    yield put(fetchPayeeFailed(e));
  }
}

export function* getPrevTransactionRequest(action) {
  try {
    const data = yield call(API.getTransactionList, action.payload);

    yield put(getPrevTransactionSucceeded(data));
  } catch (e) {
    yield put(getPrevTransactionFailed(e));
  }
}

export function* get2TransactionRequest(action) {
  try {
    const data = yield call(API.getTransactionList, action.payload);

    yield put(getTransactionSucceeded(data));
  } catch (e) {
    yield put(getTransactionFailed(e));
  }
}

export function transactionSuccessNotify(action) {
  action.payload.successCallback('Success', action.payload.message);
}

export function transactionFailureNotify(action) {
  action.payload.failureCallback(action.payload.messages);
}

export function transactionConfirmSuccessNotify(action) {
  action.payload.successCallback(action.payload);
}

export function transactionConfirmFailureNotify(action) {
  action.payload.failureCallback(action.payload);
}

export function* GetUserInfo() {
  yield takeLatest(FETCH_USER_INFO_INITIATED, getUserInfoRequest);
}

export function* TransactionSuccess() {
  yield takeEvery(ADD_TRANSACTION_SUCCEEDED, transactionSuccessNotify);
}

export function* TransactionFailure() {
  yield takeEvery(ADD_TRANSACTION_FAILED, transactionFailureNotify);
}

export function* TransactionConfirmSuccess() {
  yield takeLatest(TRANSACTION_CONFIRMATION_SUCCEEDED, transactionConfirmSuccessNotify);
}
export function* TransactionConfirmFailure() {
  yield takeLatest(TRANSACTION_CONFIRMATION_FAILED, transactionConfirmFailureNotify);
}

export function* ConfirmTransactionAction() {
  yield takeLatest(TRANSACTION_CONFIRMATION_INITIATED, transactionConfirmationRequest);
}

export function* GetTransaction() {
  yield takeLatest(FETCH_TRANSACTION_INITIATED, getTransactionRequest);
}

export function* Get2Transaction() {
  yield takeLatest(GET_TRANSACTION_INITIATED, get2TransactionRequest);
}

export function* AddTransaction() {
  yield takeLatest(ADD_TRANSACTION_INITIATED, addTransactionRequest);
}

export function* GetTransactionInfo() {
  yield takeLatest(FETCH_TRANSACTION_INFO_INITIATED, getTransactionInfoRequest);
}

export function* GetMoreTransaction() {
  yield takeLatest(GET_MORE_TRANSACTION_INITIATED, getMoreTransactionRequest);
}

export function* GetPrevTransaction() {
  yield takeLatest(GET_PREVIOUS_TRANSACTION_INITIATED, getPrevTransactionRequest);
}

export function* GetPayeeInformation() {
  yield takeLatest(GET_PAYEE_INFO_INITIATED, getPayeeInformationRequest);
}

export function* getTransactionInfoRequest(action) {
  try {
    const data = yield call(API.getTransactionInfo, action.payload);
    yield put(fetchTransactionInfoSucceeded(data));
  } catch (e) {
    yield put(fetchTransactionInfoFailed(e));
  }
}

export function* SetUseLedgerOption() {
  yield takeLatest(SET_USE_LEDGER_OPTION, () => {});
}

export const selectTransaction = (state) => state?.transaction;

export const selectSortedTransactions = (state) =>
  state?.transaction?.Items?.sort((a, b) => {
    const dateA = new Date(a.created);
    const dateB = new Date(b.created);
    if (dateA < dateB) {
      return 1;
    }
    if (dateA > dateB) {
      return -1;
    }
    return 0;
  });

export const selectTransactionError = (state) => state?.transaction?.error;

export const selectAddTransactionError = (state) => state?.transaction?.addTransactionError;

export const selectFilters = (state) => state?.transaction?.filters;

export const selectPayees = (state) => state?.transaction?.payees;

export default function transactionReducer(state = INITIAL_STATE, action) {
  const { payload } = action;
  switch (action.type) {
    case FETCH_TRANSACTION_SUCCEEDED:
      return { ...state, ...payload.transaction, loading: false, initialLoading: true };
    case FETCH_TRANSACTION_FAILED:
      return { ...state, error: payload, loading: false };
    case FETCH_TRANSACTION_INITIATED:
      return {
        ...state,
        loading: !state.initialLoading,
      };
    case FILTER_TRANSACTION_INITIATED:
      return {
        ...state,
        cursorCache: [''],
        cursor: '',
        filters: payload,
      };
    case GET_TRANSACTION_SUCCEEDED:
      return { ...state, ...payload.transaction, paginationLoading: false };
    case GET_TRANSACTION_FAILED:
      return { ...state, error: payload, paginationLoading: false };
    case GET_TRANSACTION_INITIATED:
      return {
        ...state,
        paginationLoading: true,
      };
    case GET_MORE_TRANSACTION_INITIATED:
      return {
        ...state,
        cursor: state.nextCursor,
        paginationLoading: true,
      };
    case GET_MORE_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        ...payload.transaction,
        cursorCache: [...state.cursorCache, state.cursor],
        paginationLoading: false,
      };
    case GET_MORE_TRANSACTION_FAILED:
      return {
        ...state,
        paginationLoading: false,
        cursor: state.cursorCache[state.cursorCache.length - 1],
        error: payload,
      };
    case GET_PREVIOUS_TRANSACTION_INITIATED:
      return {
        ...state,
        paginationLoading: true,
        cursor: state.cursorCache[state.cursorCache.length - 2],
      };
    case GET_PREVIOUS_TRANSACTION_SUCCEEDED:
      state.cursorCache.pop();
      return {
        ...state,
        ...payload.transaction,
        paginationLoading: false,
      };
    case GET_PREVIOUS_TRANSACTION_FAILED:
      return {
        ...state,
        paginationLoading: false,
        cursor: state.cursorCache[state.cursorCache.length - 1],
        error: payload,
      };
    case TRANSACTION_CONFIRMATION_INITIATED:
      return {
        ...state,
        loading: true,
        record: payload,
      };
    case TRANSACTION_CONFIRMATION_SUCCEEDED:
      return {
        ...state,
        loading: false,
        transactionConfirmationResponse: payload,
      };
    case TRANSACTION_CONFIRMATION_FAILED:
      return {
        ...state,
        transactionConfirmationResponse: payload,
        loading: false,
      };
    case ADD_TRANSACTION_INITIATED:
      return {
        ...state,
        loading: true,
      };
    case ADD_TRANSACTION_SUCCEEDED:
      return {
        ...state,
        loading: false,
        addTransactionResponse: payload,
      };
    case ADD_TRANSACTION_FAILED:
      return {
        ...state,
        loading: false,
        addTransactionError: payload,
      };
    case FETCH_USER_INFO_INITIATED:
      return {
        ...state,
        userInfoCheck: { ...state.userInfoCheck, loading: true },
      };
    case FETCH_USER_INFO_SUCCEEDED:
    case FETCH_USER_INFO_FAILED:
      return {
        ...state,
        userInfoCheck: { ...state.userInfoCheck, response: { ...payload }, loading: false },
      };
    case CLEAR_USER_INFO_ERROR_SUCCESS:
      return {
        ...state,
        userInfoCheck: {
          ...state.userInfoCheck,
          response: { ...state.userInfoCheck?.response, success: false, error: false },
        },
      };
    case FETCH_TRANSACTION_INFO_INITIATED:
      return {
        ...state,
        transactionInfoLoading: !state.initialTransactionInfoLoading,
      };
    case FETCH_TRANSACTION_INFO_SUCCEEDED:
      return {
        ...state,
        transactionInfoLoading: false,
        initialTransactionInfoLoading: true,
        fetchTransactionInfoSuccessResponse: payload,
      };
    case FETCH_TRANSACTION_INFO_FAILED:
      return {
        ...state,
        fetchTransactionInfoErrorResponse: payload,
        transactionInfoLoading: false,
      };
    case SET_TRANSACTION_LIMIT:
      return {
        ...state,
        cursorCache: [''],
        cursor: '',
        limit: payload,
      };
    case CLEAR_FETCH_TRANSACTION_INFO:
      return {
        ...state,
        transactionInfoLoading: false,
        initialTransactionInfoLoading: false,
        loading: false,
        fetchTransactionInfoSuccessResponse: {},
      };
    case GET_PAYEE_INFO_INITIATED:
      return {
        ...state,
        payees: { fetchPayee: true },
      };
    case GET_PAYEE_INFO_SUCCEEDED:
      return {
        ...state,
        payees: { ...payload, fetchPayee: false },
      };
    case GET_PAYEE_INFO_FAILED:
      return {
        ...state,
        payees: { data: {}, fetchPayee: false },
        error: payload,
      };
    case SET_USE_LEDGER_OPTION:
      return { ...state, useLedger: payload };

    default:
      return state;
  }
}
