import { notification } from 'antd';
import CopyToClipboard from '../components/CopyToClip';
import { format } from 'date-fns';
/**
 * Check if input string is a valid JSON string
 * @param {string} str string to test if JSON
 * @returns boolean
 */
const isJSON = (str) => {
  try {
    const parsedValue = JSON.parse(str);
    if (typeof parsedValue === 'string') return false;

    return parsedValue && !!str;
  } catch (e) {
    return false;
  }
};

/**
 * Format JSON string into a proper JSX error with ability to copy entire error object
 * @param {string} jsonString valid JSON string to format
 * @returns JSX
 */
const formatJsonStringError = (jsonString) => {
  const json = JSON.parse(jsonString);
  const endPoint = json?.request?.options?.url || '';
  const response = json.response || '';
  let obfuscatedJsonString = '';

  if (json?.request?.options?.headers?.Authorization) {
    const jwt = json.request.options.headers.Authorization;
    obfuscatedJsonString = jsonString.replace(jwt, '****');
  }

  return (
    <div>
      <p>
        There was an error making a request on {endPoint}, response: {response || 'null'}
      </p>
      <p>
        <CopyToClipboard copyText={obfuscatedJsonString || jsonString}>Click here</CopyToClipboard>{' '}
        to copy the complete error details. Contact support if the error persists.
      </p>
    </div>
  );
};

/**
 * notification error handler
 * @param {string} messageHeading message head text
 * @param {string} messageDescription string or JSON string representing error message
 */
export const openError = (messageHeading, messageDescription) => {
  const description = isJSON(messageDescription)
    ? formatJsonStringError(messageDescription)
    : messageDescription;

  notification.error({
    message: messageHeading,
    description,
  });
};

export const openNotification = (
  placement = 'bottomRight',
  requestionInfo,
  title = 'Successful',
) => {
  notification.success({
    message: `Request ${JSON.stringify(title, null, '\n')}`,
    description: `${JSON.stringify(requestionInfo, null, '\n')} `,
    placement,
  });
};

const removeAmountSpecialCharacters = (amount) => amount.replace(/[,\s]/g, '');
export const formatAmount = (value) =>
  (Math.round(removeAmountSpecialCharacters(value) * 100) / 100).toFixed(2);

export const formatMoney = (num) => {
  const p = parseFloat(num).toFixed(2).split('.');
  const formattedMoney = `${p[0]
    .split('')
    .reverse()
    .reduce((acc, num, i) => {
      return num === '-' ? `-${acc}` : num + (i && !(i % 3) ? ',' : '') + acc;
    }, '')}.${p[1]}`;
  return `${formattedMoney}`;
};

export const diffMinutes = (dt2, dt1) => {
  const diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60;
  return Math.abs(Math.round(diff));
};

export const snakeCaseToTitle = (str) => {
  return str
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const userBalanceToArray = (balance) => {
  const balanceArray = [];
  Object.keys(balance).forEach((key) => {
    balanceArray.push({
      key: key,
      currency: key,
      availableBalance: formatMoney(balance[key].available),
      ledgerBalance: formatMoney(balance[key].ledger),
    });
  });

  return balanceArray;
};

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const showTimeInHKT = () => {
  // create Date object for current location
  var d = new Date();

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  var utc = d.getTime() + d.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  var nd = new Date(utc + 3600000 * 8);

  // return time as a string
  return nd.toLocaleString() + ' HKT';
};

export const formatNKDate = (dateValue) => {
  return format(new Date(dateValue), 'yyyy-MM-dd');
};

export const formatNKAmount = (amount) => {
  return parseFloat(amount.toString().replace(/,/g, ''));
};
