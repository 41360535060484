import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSignInSucceeded,
  fetchSignInFailed,
  fetchPasswordResetSucceeded,
  fetchPasswordResetFailed,
} from './actions';
import {
  FETCH_SIGNIN_INITIATED,
  FETCH_SIGNIN_SUCCEEDED,
  FETCH_SIGNIN_FAILED,
  FETCH_PASSWORD_RESET_INITIATED,
  FETCH_PASSWORD_RESET_SUCCEEDED,
  FETCH_PASSWORD_RESET_FAILED,
  FETCH_SIGNOUT_INITIATED,
} from './types';
import API from './requests';

/**
 * This handles authentication and was added to persist user object in redux to enable passing it across views
 */
const INITIAL_STATE = {
  user: null,
  loading: false,
  error: null,
  passwordReset: false,
};

function* signInRequest(action) {
  try {
    const user = yield call(API.signIn, action.payload);

    yield put(fetchSignInSucceeded(user));
  } catch (e) {
    yield put(fetchSignInFailed(e));
  }
}

function* resetPasswordRequest(action) {
  try {
    const user = yield call(API.resetUserPassword, action.payload);

    yield put(fetchPasswordResetSucceeded(user));
  } catch (e) {
    yield put(fetchPasswordResetFailed(e));
  }
}

export function* GetAuth() {
  yield takeLatest(FETCH_SIGNIN_INITIATED, signInRequest);
  yield takeLatest(FETCH_PASSWORD_RESET_INITIATED, resetPasswordRequest);
}

export const selectAuth = (state) => state.auth;

export default function reducer(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch (action.type) {
    case FETCH_SIGNOUT_INITIATED:
      return {
        ...state,
        loading: false,
        passwordReset: false,
        user: null,
      };
    case FETCH_SIGNIN_SUCCEEDED:
      return { user: payload, loading: false };
    case FETCH_PASSWORD_RESET_SUCCEEDED:
      return { ...state, loading: false, passwordReset: true };
    case FETCH_SIGNIN_FAILED:
    case FETCH_PASSWORD_RESET_FAILED:
      return { error: payload, loading: false, passwordReset: false };
    case FETCH_SIGNIN_INITIATED:
    case FETCH_PASSWORD_RESET_INITIATED:
      return {
        ...state,
        passwordReset: false,
        loading: true,
      };
    default:
      return state;
  }
}
